export default {
  KEY: {},
  USER: {
    LOGIN: 'user/login',
    AUTO_LOGIN: 'user/auto-login',
    GET_LIST: 'user/get-list',
    DELETE: 'user/delete',
    REGISTER: 'user/register',
    UPDATE: 'user/update'
  },
  GROUP: {
    GET_LIST: 'group/get-list',
    ADD: 'group/add',
    DELETE: 'group/delete',
    ARCHIVE: 'group/archive',
    ADD_USER: 'group/add-user',
    DELETE_USER: 'group/delete-user'
  },
  DMR: {
    UPADTE_COURSE: 'dmr/update-course',
    GET_LIST_COURSE: 'dmr/get-list-course',
    GET_PARTNER_INFO: 'dmr/get-partner-info',
    GET_USERS_PROGRESS: 'dmr/get-users-progress',
    GET_USER_PROGRESS_DETAIL: 'dmr/get-user-progress-detail',
    GET_USERS_TESTING: 'dmr/get-users-testing',
    GET_USERS_INFO: 'dmr/get-users-info',
    GET_ALL_USERS: 'dmr/get-all-users'
  }
};
