import Store from 'redux/Store';
import GroupActionType from 'redux/types/GroupActionType';

export default {
  getGroups: () => Store.dispatch({ type: GroupActionType.GET_LIST_GROUP }),
  addGroup: (group) => Store.dispatch({ type: GroupActionType.ADD_GROUP, group }),
  deleteGroup: (group) => Store.dispatch({ type: GroupActionType.DELETE_GROUP, group }),
  archiveGroup: (group) => Store.dispatch({ type: GroupActionType.ARCHIVE_GROUP, group }),
  getGroupUsers: () => Store.dispatch({ type: GroupActionType.GET_LIST_GROUP_USER })
};
