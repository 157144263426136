import { Box, ButtonBase, FormControl, Grid, InputBase, MenuItem, Popover, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';
import Colors from 'assets/Colors';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ExportData from './ExportData';
import styles from './styles.module.css';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 15,
    marginTop: 10,
    paddingBottom: 5
  }
}));

export default ({ searchHint = 'Tìm kiếm tên', onSelected = null, onSearch = null, renderButton, data = [], exportFields = [] }) => {
  const [groupIndex, setGroupIndex] = useState(0);
  const [subGroupIndex, setSubGroupIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [subGroupOpen, setSubGroupOpen] = useState(false);
  const groups = useSelector((state) => state.groupReducer.groups) || [];
  const currentGroup = groups[groupIndex];
  const inputSearch = useRef(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onGroupClose = () => setOpen(false);
  const onGroupOpen = () => setOpen(true);
  const onSubGroupClose = () => setSubGroupOpen(false);
  const onSubGroupOpen = () => setSubGroupOpen(true);

  const onSubMenuSelected = (event) => {
    const index = event.target.value;
    setSubGroupIndex(index);
    onSelected(currentGroup?.subGroup[index].id);
  };

  const onGroupSelected = (event) => {
    const groupIndex = event.target.value;
    setGroupIndex(groupIndex);

    // Callback
    if (!groups[groupIndex].subGroup || groups[groupIndex].subGroup.length < 1) {
      onSelected(groups[groupIndex].id);
    } else {
      onSelected(groups[groupIndex].subGroup[0].id);
    }
  };

  const onChangeTextSearch = (ev) => {
    onSearch(ev.target.value);
  };

  // const onClickSearch = () => {
  //   onSearch(inputSearch.current.value);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openExport = Boolean(anchorEl);
  const id = openExport ? 'simple-popover' : undefined;
  const exportData = data.filter((e) => e.checked);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }} mb={4}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item sm={'auto'} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item sm={'auto'} xs={12} style={{ marginRight: 30 }}>
              {onSelected && groups.length > 0 && (
                <>
                  <FormControl variant="standard">
                    <Select
                      IconComponent={() => <ArrowDropDownIcon onClick={onGroupOpen} style={{ color: Colors.primary }} />}
                      disableUnderline
                      open={open}
                      onClose={onGroupClose}
                      onOpen={onGroupOpen}
                      onChange={onGroupSelected}
                      value={currentGroup?.name}
                      renderValue={() => <Typography style={{ color: Colors.primary, fontWeight: '600', marginRight: -20 }}>{currentGroup?.name}</Typography>}>
                      {groups.map((item, index) => (
                        <MenuItem key={index} value={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {currentGroup?.subGroup?.length > 0 && (
                    <FormControl variant="standard">
                      <Select
                        IconComponent={() => <ArrowDropDownIcon onClick={onSubGroupOpen} style={{ color: Colors.primary }} />}
                        disableUnderline
                        open={subGroupOpen}
                        onClose={onSubGroupClose}
                        onOpen={onSubGroupOpen}
                        onChange={onSubMenuSelected}
                        value={currentGroup?.subGroup[subGroupIndex].name}
                        renderValue={() => (
                          <Typography style={{ color: Colors.primary, fontWeight: '600' }}>{currentGroup?.subGroup[subGroupIndex].name}</Typography>
                        )}>
                        {currentGroup?.subGroup?.map((item, index) => (
                          <MenuItem key={index} value={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
            </Grid>

            <Grid item sm={'auto'} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              {onSearch && (
                <>
                  <div className={styles.search}>
                    <div className={styles.iconSearch}>
                      <SearchIcon fontSize={'small'} style={{ color: '#CACCCF' }} />
                    </div>
                    <InputBase
                      inputRef={inputSearch}
                      className={styles.input}
                      placeholder={searchHint}
                      style={{ fontSize: 14 }}
                      onChange={onChangeTextSearch}
                    />
                  </div>
                  {/* <ButtonBase
                    onClick={onClickSearch}
                    style={{
                      height: 32,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 30,
                      backgroundColor: Colors.primary
                    }}>
                    <Typography component={'p'} variant={'body2'} style={{ color: 'white' }}>
                      Tìm kiếm
                    </Typography>
                  </ButtonBase> */}
                </>
              )}
            </Grid>
          </Grid>

          <Grid item sm={'auto'} xs={12}></Grid>

          <Grid item sm={3} xs={12}>
            {renderButton && (
              <Grid container direction="column" alignItems="flex-end" justifyContent="center">
                {renderButton()}
              </Grid>
            )}
          </Grid>

          {exportFields.length > 0 && (
            <Grid item sm={2} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonBase aria-describedby={id} aria-haspopup="true" onClick={handleClick}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <InsertDriveFileIcon />
                  <Typography component={'p'} variant={'body2'} gutterBottom>
                    Trích xuất dữ liệu
                  </Typography>
                </Grid>
              </ButtonBase>
              <Popover
                id={id}
                open={openExport}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{
                  paper: classes.paper
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}>
                <ExportData exportFields={exportFields} data={exportData} nameGroup={currentGroup?.name} onClickCancel={handleClose} />
              </Popover>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};
