import Funcs from 'common/helpers/Funcs';
import DataConst from 'consts/DataConst';
import Configs from './Configs';

const gradeMark = [20, 30, 50, 60];
const states = ['Yếu', 'Trung bình', 'Khá', 'Giỏi'];
const stateColors = ['#D73A3A', '#A38B36', '#41A336', '#41A336'];

const Utils = {
  viewingUserId: 0,
  groups: [],
  async init() {
    Funcs.log('Utils init');
    Configs.token = localStorage.getItem(DataConst.USER_TOKEN);
  },

  findGroupById: (groupId) => {
    let group = null;

    // Find group
    for (let i = 0; i < Utils.groups.length; i += 1) {
      const item = Utils.groups[i];
      if (item.id === groupId) {
        group = item;
        break;
      }

      // Finh in sub group
      for (let j = 0; j < item?.subGroup?.length; j += 1) {
        if (item?.subGroup[j].id === groupId) {
          group = { ...item, sub: item?.subGroup[j] };
          break;
        }
      }
      if (group) break;
    }
    return group;
  },

  release() {},
  
  getGradeState(grade) {
    if (!grade) grade = 0;
    let stateIndex = 0;
    for (let i = 0; i < gradeMark.length; i += 1) {
      if (grade < gradeMark[i]) {
        stateIndex = i;
        break;
      }
    }
    return { state: states[stateIndex], color: stateColors[stateIndex] };
  }
};

export default Utils;
