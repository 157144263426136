import { ButtonBase, Typography } from '@material-ui/core';
import React, { useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import UserActionCreator from 'redux/actionCreators/UserActionCreator';
import styles from './styles.module.css';
import Configs from 'utils/Configs';

export default () => {
  const alert = useAlert();
  const inputUserName = useRef(null);
  const inputPassword = useRef(null);

  const onClick = async () => {
    if (!inputUserName.current.value || !inputPassword.current.value) {
      alert.error('Mời Bạn nhập đủ thông tin');
      return;
    }
    const errorCallback = (message) => {
      alert.error(message);
    };
    UserActionCreator.login(inputUserName.current.value, inputPassword.current.value, errorCallback);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  useEffect(() => {
    if (Configs.isDev) {
      inputUserName.current.value = 'dungmori@gmail.com';
      inputPassword.current.value = '123456';
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box}>
          <img className={styles.logo} src={'https://dungmori.com/assets/img/new_home/header_logo.png'} />
          <span className={styles.textCenter}>Hệ thống quản lý học tập của Dũng Mori</span>
          <input className={styles.inputContainer} ref={inputUserName} type="text" onKeyDown={onKeyDown} placeholder="Tên đăng nhập" />
          <input className={styles.inputContainer} ref={inputPassword} type="password" onKeyDown={onKeyDown} placeholder="Mật khẩu" />
          <ButtonBase
            onClick={onClick}
            className={styles.btn}
            style={{
              backgroundColor: '#41A336',
              borderRadius: 5,
              marginTop: 20,
              marginBottom: 15
            }}>
            <Typography component={'p'} variant={'body2'} style={{ color: '#fff', fontSize: 14 }}>
              Đăng nhập
            </Typography>
          </ButtonBase>
          <Link to="/forgot-password" className={styles.textForgotPass}>
            Quên mật khẩu?
          </Link>
        </div>
      </div>
    </div>
  );
};
