const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

let mode = 'local';
mode = 'dev';
// mode = 'prod';

if (!development) {
  // mode = 'prod';
}

export default {
  // startScreen: ScreenConst.MAIN_SCREEN,
  mode,
  isDev: mode !== 'prod',
  token: '',
  user: {
    // id: 1,
    // name: 'DG'
  }
};
