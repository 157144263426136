import { ButtonBase, Checkbox, TableCell, TableRow, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/styles';
import Time from 'common/helpers/Time';
import UserInfoSmallView from 'components/views/UserInfoSmallView';
import React from 'react';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

export default ({ item, onClickItem, onClickDeleteItem, type }) => {
  const onClick = () => onClickItem(item);
  const onClickDelete = () => onClickDeleteItem(item);
  return (
    <TableRow padding={'none'}>
      <TableCell align={'center'} padding={'none'}>
        <Checkbox onClick={onClick} checked={!!item.checked} color={'primary'} />
      </TableCell>
      <TableCell padding={'none'}>
        <UserInfoSmallView user={item} type={type} />
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.japanese_level || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.group_name}</TypographyContent>
      </TableCell>

      <TableCell padding={'none'}>
        <TypographyContent>{item.phone || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.email || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{Time.format(item.created_at, 'DD/MM/YYYY')}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <ButtonBase style={{ padding: 5 }} onClick={onClickDelete}>
          <Delete htmlColor={'red'} />
        </ButtonBase>
      </TableCell>
    </TableRow>
  );
};
