import { ButtonBase, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import Consts from 'consts/Consts';
import React from 'react';
import { withAlert } from 'react-alert';
import { MdAddCircleOutline } from 'react-icons/md';
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleFill } from 'react-icons/ri';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilterView from 'screens/BaseScreen/FilterView';
import { ProgressScreen, TypographyTitle } from 'screens/ProgressScreen';
import ItemTableInfo from './ItemTableInfo';
import ModalAddPartner from './ModalAddPartner';
import styles from './styles.module.css';

export class PartnerManagerScreen extends ProgressScreen {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  async componentDidMount() {
    const data = await Fetch.get(ApiConst.USER.GET_LIST, {}, true);
    if (data.status === 200) {
      this.users = data.data.map((e) => {
        const find = Consts.USER_ROLES.find((e1) => e1.value === e.role);
        if (find) e.roleType = find.name;
        return e;
      });
      this.updateDataForPage(1);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.newUser && nextProps.newUser !== this.props.newUser) {
      this.users.push(nextProps.newUser);
      this.userSearchs = this.users;
      this.updateDataForPage(1);
    }
    if (nextProps.userRemoved && nextProps.userRemoved !== this.props.userRemoved) {
      this.users = this.users.filter((e) => nextProps.userRemoved.id !== e.id);
      this.userSearchs = this.users;
      this.updateDataForPage(1);
    }
    if (nextProps.userUpdated && nextProps.userUpdated !== this.props.userUpdated) {
      this.users = this.users.map((e) => {
        if (nextProps.userUpdated.id === e.id) {
          e = { ...e, ...nextProps.userUpdated };
        }
        return e;
      });
      this.userSearchs = this.users;
      this.updateDataForPage(1);
    }
    return this.state !== nextState;
  }

  renderBoxInfo = () => null;

  onCickAddGroup = () => {
    this.ModalPartner.show();
  };

  onCickDeleteGroup = () => {
    const selected = this.state.data.filter((e) => e.checked);
    if (selected.length < 1) {
      this.props.alert.error('Mời bạn chọn ít nhất một doanh nghiệp trước');
      return;
    }
  };

  onClickEditItem = (item) => {
    this.ModalPartner.show(item);
  };

  renderTableHead = () => {
    const { checkAll } = this.state;
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell width={'10%'} align={'center'}>
            <ButtonBase style={{ display: 'inline-block' }} onClick={this.onClickCheckAll}>
              {checkAll ? <RiCheckboxMultipleFill size={20} /> : <RiCheckboxMultipleBlankLine size={20} />}
              <Typography style={{ fontSize: 9, padding: 0, lineHeight: 0.5, fontWeight: 'bold' }}>{'Chọn tất cả'}</Typography>
            </ButtonBase>
          </TableCell>
          <TableCell width={'20%'} padding={'none'}>
            <TypographyTitle>{'Tên doanh nghiệp'}</TypographyTitle>
          </TableCell>
          <TableCell width={'18%'} padding={'none'}>
            <TypographyTitle>{'Email'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'SĐT'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Mã DN'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Loại'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Ngày bắt đầu'}</TypographyTitle>
          </TableCell>
          <TableCell width={'7%'} padding={'none'}>
            <TypographyTitle>{'Hành động'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderItem = (item, i) => {
    return <ItemTableInfo key={i} item={item} onClickItem={this.onClickItem} onClickEditItem={this.onClickEditItem} />;
  };

  renderButton = () => {
    const style = { width: 100, padding: 10, margin: 5, display: 'inline-block' };
    return (
      <div className={styles.viewIcon}>
        <ButtonBase onClick={this.onCickAddGroup} style={style}>
          <MdAddCircleOutline size={22} color={'#96D962'} />
          <Typography style={{ fontSize: 14, marginTop: 2, fontWeight: '500' }}>{'Thêm'}</Typography>
        </ButtonBase>
        {/* <ButtonBase onClick={this.onCickDeleteGroup} style={style}>
          <MdRemoveCircleOutline size={22} color={'#D73A3A'} />
          <div style={{ fontSize: 14, marginTop: 2, fontWeight: '500', color: '#D73A3A' }}>{showDelete ? 'Hủy bỏ' : `Xóa`}</div>
        </ButtonBase> */}
      </div>
    );
  };

  renderFilterView = () => {
    return <FilterView renderButton={this.renderButton} onSearch={this.onSearch} />;
  };

  renderModal = () => {
    return <ModalAddPartner ref={(ref) => (this.ModalPartner = ref)} />;
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return { newUser: state.userReducer.newUser, userUpdated: state.userReducer.userUpdated, userRemoved: state.userReducer.userRemoved };
};
export default withAlert()(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(PartnerManagerScreen)));
