import { Checkbox, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import UserInfoSmallView from 'components/views/UserInfoSmallView';
import React from 'react';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

export default ({ item, onClickItem, type, index }) => {
  const onClick = () => {
    onClickItem(item);
  };
  const { isCompleteProgress } = item;
  return (
    <TableRow padding={'none'}>
      <TableCell align={'center'} padding={'none'}>
        <Checkbox onClick={onClick} checked={!!item.checked} color={'primary'} />
      </TableCell>
      <TableCell padding={'none'} align={'center'}>
        {index > 2 ? index + 1 : <img src={'images/ranking/top' + (index + 1) + '.png'} style={{ width: 30, height: 35 }} />}
      </TableCell>
      <TableCell padding={'none'}>
        <UserInfoSmallView user={item} type={type} />
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.course_name || 'Chưa có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.group_name}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.learning_time || 0} giờ</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{(item.progress || 0) + '%'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent style={{ color: isCompleteProgress ? '#41A336' : '#D73A3A' }}>{isCompleteProgress ? 'Đạt tiến độ' : 'Chưa đạt'}</TypographyContent>
      </TableCell>
    </TableRow>
  );
};
