import GroupActionType from 'redux/types/GroupActionType';

const initialState = {};

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GroupActionType.GET_LIST_GROUP_SUCCESS:
      return { ...state, groups: action.groups };
    case GroupActionType.GET_LIST_GROUP_USER_SUCCESS:
      return { ...state, groups: action.groups };
    case GroupActionType.ADD_GROUP: {
      let { groups } = state;
      if (action.group.parent_id) {
        groups = groups.map((e) => {
          if (e.id === parseInt(action.group.parent_id)) {
            if (!e.subGroup) e.subGroup = [];
            e.subGroup = [...e.subGroup, action.group];
          }
          return e;
        });
      } else {
        groups = [...groups, action.group];
      }
      return { ...state, groups };
    }
    case GroupActionType.DELETE_GROUP: {
      let { groups } = state;
      groups = groups.filter((e) => e.id !== action.group.id);
      return { ...state, groups, deleteGroup: action.group };
    }
    case GroupActionType.ARCHIVE_GROUP: {
      let { groups } = state;
      groups = groups.map((e) => {
        if (e.id === action.group.id) {
          e = { ...e, status: 0 };
        }
        return e;
      });
      return { ...state, groups, deleteGroup: action.group };
    }
    default:
      return state;
  }
};
