import { ButtonBase, Checkbox, TableCell, TableRow, Typography } from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/styles';
import Colors from 'assets/Colors';
import ModalConfirm from 'common/components/modals/ModalConfirm';
import Fetch from 'common/helpers/Fetch';
import Time from 'common/helpers/Time';
import UserInfoSmallView from 'components/views/UserInfoSmallView';
import ApiConst from 'consts/ApiConst';
import React from 'react';
import { useAlert } from 'react-alert';
import { TiEyeOutline } from 'react-icons/ti';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserActionCreator from 'redux/actionCreators/UserActionCreator';
import Utils from 'utils/Utils';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

export default ({ item, type, onClickItem, onClickEditItem }) => {
  const alert = useAlert();
  const history = useHistory();
  const onClickEdit = () => {
    onClickEditItem(item);
  };
  const onClickRemove = () => {
    ModalConfirm.show({
      title: 'Xóa doanh nghiệp',
      content: 'Bạn có chắc chắn muốn xóa ' + (item.role === 1 ? 'doanh nghiệp' : 'Trường học') + item.name + ' không?',
      onClickOk: async () => {
        const res = await Fetch.post(ApiConst.USER.DELETE, { id: item.id }, true);
        if (res.status === 200) {
          UserActionCreator.removeUser(item);
          alert.success('Thành công');
        } else {
          alert.error(res.data?.message || 'Thất bại');
        }
      }
    });
  };
  const onClick = () => {
    onClickItem(item);
  };
  const onClickView = () => {
    history.push('/' + item.id);
    Utils.viewingUserId = item.id;
  };

  return (
    <TableRow padding={'none'}>
      <TableCell align={'center'} padding={'none'}>
        <Checkbox onClick={onClick} checked={!!item.checked} color={'primary'} />
      </TableCell>
      <TableCell padding={'none'}>
        <UserInfoSmallView disabled user={item} type={type} />
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.email || 'Chưa có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.phone || 'Chưa có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.partner_id || 0}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.roleType || ''}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{Time.format(item.created_date, 'DD/MM/YYYY')}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        {/* <ButtonBase onClick={onClickView}>
          <TiEyeOutline color={Colors.primary} size={20} style={{ margin: 3 }} />
        </ButtonBase> */}
        <ButtonBase onClick={onClickEdit}>
          <EditOutlined htmlColor={Colors.primary} style={{ width: 18, height: 18, margin: 3 }} />
        </ButtonBase>
        <ButtonBase onClick={onClickRemove}>
          <DeleteOutline htmlColor={'red'} style={{ width: 18, height: 18, margin: 3 }} />
        </ButtonBase>
      </TableCell>
    </TableRow>
  );
};
