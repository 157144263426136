import Fetch from 'common/helpers/Fetch';
import Button from 'components/views/Button';
import ApiConst from 'consts/ApiConst';
import UrlConst from 'consts/UrlConst';
import React, { PureComponent } from 'react';
import { Ring } from 'react-awesome-spinners';
import { AiFillPrinter, AiOutlineMail } from 'react-icons/ai';
import { BiBookBookmark, BiUserCircle } from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';
import Utils from 'utils/Utils';
import ChartsView from './ChartView';
import LearningProgress from './LearningProgress';
import styles from './styles.module.css';
import JLPTResult from './JLPTResult';

class ItemInfo extends PureComponent {
  render() {
    const { icon, title } = this.props;
    return (
      <div className={styles.viewItemInfo}>
        {icon}
        <div style={{ marginLeft: 10, color: '#52575C' }}>{title}</div>
      </div>
    );
  }
}

export default class ModalUserInfo extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      user: {}
    };
    ModalUserInfo.instance = this;
  }

  static show = (user) => {
    ModalUserInfo.instance?.setState({ visible: true, loading: true, user }, ModalUserInfo.instance?.getData);
  };

  static hide = () => {
    ModalUserInfo.instance?.setState({ visible: false });
  };

  getData = async () => {
    let { user } = this.state;
    const res = await Fetch.get(ApiConst.DMR.GET_USER_PROGRESS_DETAIL, { id: user.id }, true);
    if (res.status === 200) {
      user = { ...user, ...res.data };
      this.setState({ user });
    }
    this.setState({ loading: false });
  };

  onClickPrinter = () => {};

  renderContent = () => {
    const { user, loading } = this.state;
    if (loading) {
      return (
        <center>
          <Ring />
        </center>
      );
    }
    return (
      <div>
        <LearningProgress data={user} />
        <ChartsView user={user} />
        <JLPTResult user={user} />
      </div>
    );
  };

  render() {
    const { user } = this.state;
    const group = Utils.findGroupById(user.group_id);
    return (
      <Modal
        ariaHideApp={false}
        onRequestClose={ModalUserInfo.hide}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 1132, maxHeight: 710, padding: 0, margin: 'auto' }
        }}>
        <div>
          <Button style={{ position: 'absolute', right: 0, top: 0 }} onClick={ModalUserInfo.hide}>
            <MdClose size={24} />
          </Button>
          <div style={{ padding: 20, paddingLeft: 25, paddingRight: 25 }} ref={(el) => (this.componentRef = el)}>
            <div className={styles.container}>
              <img className={styles.avatar} src={user.avatar ? UrlConst.DUNGMORI_CDN + 'avatar/default/' + user.avatar : 'images/default_avatar.png'} />
              <div className={styles.header}>
                <div style={{ fontSize: 26, fontWeight: '500' }}>{user.name}</div>
                <div className={styles.viewInfo}>
                  <div>
                    <ItemInfo icon={<BiUserCircle size={22} color={'#222'} />} title={group?.name} />
                    <ItemInfo
                      icon={<HiOutlineUserGroup size={20} color={'#222'} />}
                      title={user.year ? new Date().getFullYear() - user.year + ' tuổi' : 'Không rõ'}
                    />
                  </div>
                  <div style={{ marginLeft: 100 }}>
                    <ItemInfo icon={<SiGoogleclassroom size={18} color={'#222'} />} title={group?.sub?.name || 'Không có'} />
                    <ItemInfo icon={<BiBookBookmark size={20} color={'#222'} />} title={'Trình độ ' + (user.course_name || 'Không có')} />
                  </div>
                  <div style={{ marginLeft: 100 }}>
                    <ItemInfo icon={<AiOutlineMail size={20} color={'#222'} />} title={user.email || 'Không có'} />
                    <ItemInfo icon={<FiPhone size={18} color={'#222'} />} title={user.phone || 'Không có'} />
                  </div>
                </div>
                <div style={{ position: 'absolute', right: 10, bottom: 0 }}>
                  <ReactToPrint
                    trigger={() => {
                      return <AiFillPrinter size={22} />;
                    }}
                    content={() => this.componentRef}
                  />
                </div>
              </div>
            </div>
            {this.renderContent()}
          </div>
        </div>
      </Modal>
    );
  }
}
