import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';
import { GroupScreen } from 'screens/GroupScreen';

class SubGroupScreen extends GroupScreen {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.params = params;
    this.state = { subGroups: this.getSubGroup(props.groups) };
    this.isSchool = false;
  }

  componentDidMount() {
    if (!this.props.courses || this.props.courses?.length < 1) HomeActionCreator.getListCourse();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.groups && nextProps.groups !== this.props.groups) {
      this.setState({ subGroups: this.getSubGroup(nextProps.groups) });
    }
    if (nextProps.deleteGroup && nextProps.deleteGroup !== this.props.deleteGroup) {
      let groupSearchs = this.getShowListGroup();
      groupSearchs = groupSearchs.filter((e) => e.id !== nextProps.deleteGroup.id);
      this.setState({ groupSearchs });
    }
    return this.state !== nextState;
  }

  getSubGroup = (groups) => {
    let subGroups = [];
    if (groups) {
      for (let i = 0; i < groups.length; i += 1) {
        if (groups[i].id == this.params.groupId) {
          subGroups = groups[i].subGroup;
          HomeActionCreator.updateHeaderTitle(groups[i].name);
          break;
        }
      }
    }
    return subGroups;
  };

  onSearch = (text) => {
    let { subGroups } = this.state;
    let groupSearchs = subGroups.filter((e) => {
      return e.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
    });
    this.setState({ groupSearchs });
  };

  getShowListGroup = () => {
    let { subGroups, groupSearchs } = this.state;
    if (!groupSearchs) groupSearchs = subGroups;
    if (!groupSearchs) groupSearchs = [];
    return groupSearchs;
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return { groups: state.groupReducer.groups, deleteGroup: state.groupReducer.deleteGroup, courses: state.homeReducer.courses };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(SubGroupScreen));
