import { ButtonBase, TableCell, TableRow, Typography } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/styles';
import Fetch from 'common/helpers/Fetch';
import UserInfoSmallView from 'components/views/UserInfoSmallView';
import ApiConst from 'consts/ApiConst';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

export default ({ item, onAddUser, type, groupId }) => {
  const [adding, setAdding] = useState(false);
  const alert = useAlert();
  const onClickAdd = async () => {
    setAdding(true);
    const res = await Fetch.post(ApiConst.GROUP.ADD_USER, { dmrUserId: item.dmr_user_id, groupId }, true);
    if (res.status === 200) {
      onAddUser(item);
      alert.success('Thành công');
    } else {
      alert.error(res?.data?.message || 'Thất bại');
    }
    setAdding(false);
  };
  return (
    <TableRow padding={'none'}>
      <TableCell padding={'none'} style={{ paddingLeft: 15 }}>
        <UserInfoSmallView user={item} type={type} disabled />
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.japanese_level || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.phone || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.email || 'Không có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>
          <ButtonBase onClick={() => onClickAdd(item)} disabled={item.isAdded || adding}>
            {item.isAdded ? <Check htmlColor={'green'} /> : <Add />}
            {item.isAdded ? 'Đã thêm' : 'Thêm'}
          </ButtonBase>
        </TypographyContent>
      </TableCell>
    </TableRow>
  );
};
