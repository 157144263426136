import { ButtonBase, Typography } from '@material-ui/core';
import ItemGroup from 'components/views/ItemGroup';
import React from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupActionCreator from 'redux/actionCreators/GroupActionCreator';
import BaseScreen from 'screens/BaseScreen';
import FilterView from 'screens/BaseScreen/FilterView';
import ModalAddGroup from './ModalAddGroup';
import styles from './styles.module.css';

export class GroupScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = {};
    this.isSchool = false; // Nếu là trường học
    this.params = this.props.match.params;
  }

  async componentDidMount() {
    GroupActionCreator.getGroupUsers();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.groups && nextProps.groups !== this.props.groups) {
      this.setState({ groupSearchs: nextProps.groups });
    }
    if (nextProps.deleteGroup && nextProps.deleteGroup !== this.props.deleteGroup) {
      let groupSearchs = this.getShowListGroup();
      groupSearchs = groupSearchs.filter((e) => e.id !== nextProps.deleteGroup.id);
      this.setState({ groupSearchs });
    }
    return this.state !== nextState;
  }

  renderBoxInfo = () => null;

  onCickAddGroup = () => {
    this.ModalGroup.show();
  };

  onCickDeleteGroup = () => {
    let { showDelete } = this.state;
    showDelete = !showDelete;
    let groupSearchs = this.getShowListGroup();
    groupSearchs = groupSearchs.map((e) => {
      e = { ...e, showDelete };
      return e;
    });
    this.setState({ showDelete, groupSearchs });
  };

  onSearch = (text) => {
    let { groups } = this.props;
    let groupSearchs = groups.filter((e) => {
      return e.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
    });
    this.setState({ groupSearchs });
  };

  getShowListGroup = () => {
    let { groups } = this.props;
    let { groupSearchs } = this.state;
    if (!groupSearchs) groupSearchs = groups;
    if (!groupSearchs) groupSearchs = [];
    return groupSearchs;
  };

  renderButton = () => {
    let { showDelete } = this.state;
    const style = { width: 100, height: 70, margin: 5, display: 'inline-block' };
    const groupName = this.isSchool ? 'khóa' : 'lớp';
    return (
      <div className={styles.viewIcon}>
        <ButtonBase onClick={this.onCickAddGroup} style={style}>
          <MdAddCircleOutline size={22} color={'#96D962'} />
          <Typography style={{ fontSize: 14, marginTop: 2, fontWeight: '500' }}>{`Thêm ${groupName}`}</Typography>
        </ButtonBase>
        <ButtonBase onClick={this.onCickDeleteGroup} style={style}>
          <MdRemoveCircleOutline size={22} color={'#D73A3A'} />
          <div style={{ fontSize: 14, marginTop: 2, fontWeight: '500', color: '#D73A3A' }}>{showDelete ? 'Hủy bỏ' : `Xóa ${groupName}`}</div>
        </ButtonBase>
      </div>
    );
  };

  renderFilterView = () => {
    return <FilterView renderButton={this.renderButton} onSearch={this.onSearch} />;
  };

  renderItem = (item, index) => {
    if (item.status === 0) return null;
    return <ItemGroup key={index} item={item} isGroup={this.isSchool} />;
  };

  renderContent = () => {
    const groupSearchs = this.getShowListGroup();
    return (
      <div className={styles.container}>
        {groupSearchs?.map(this.renderItem)}
        <ModalAddGroup ref={(ref) => (this.ModalGroup = ref)} parentId={this.params?.groupId} isGroup={this.isSchool} />
      </div>
    );
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return { groups: state.groupReducer.groups, deleteGroup: state.groupReducer.deleteGroup };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(GroupScreen));
