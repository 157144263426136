import { Checkbox, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import UserInfoSmallView from 'components/views/UserInfoSmallView';
import React from 'react';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

export default ({ item, onClickItem, type }) => {
  const onClick = () => {
    onClickItem(item);
  };

  return (
    <TableRow padding={'none'}>
      <TableCell align={'center'} padding={'none'}>
        <Checkbox onClick={onClick} checked={!!item.checked} color={'primary'} />
      </TableCell>
      <TableCell padding={'none'}>
        <UserInfoSmallView user={item} type={type} />
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.course_name || 'Chưa có'}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.group_name}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{item.total || 0}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent>{(item.grade || 0) + '/' + (item.total_grade || 0)}</TypographyContent>
      </TableCell>
      <TableCell padding={'none'}>
        <TypographyContent style={{ color: item.gradeState?.color }}>{item.gradeState?.state}</TypographyContent>
      </TableCell>
    </TableRow>
  );
};
