import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

export default ({ user }) => {
  const { jlpt = []} = user;
  return (
    <div>
      <center>
        <div style={{ fontWeight: 'bold', fontSize: 21, marginTop: 26 }}>Điểm thi JLPT</div>
      </center>
      {jlpt.map((e, i) => {
        return (
          <div key={i}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>{e.name}</div>
            <Table style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc' }}>
              <TableBody>
                <TableRow style={{}}>
                  {e.lessons?.map((e1, i1) => (
                    <TableCell
                      width={'30%'}
                      key={i1}
                      align={'center'}
                      padding={'none'}
                      style={{ borderWidth: 0, borderLeftWidth: 1, borderBottomWidth: 1, borderStyle: 'solid', borderColor: '#ccc' }}>
                      <Typography style={{ padding: 5, fontSize: 14 }}>
                        {e1.name.replace('Bài thi: ', '').replace('Bài Thi: ', '').replace('Bài Thi :', '').trim()}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow style={{}}>
                  {e.lessons?.map((e1, i1) => (
                    <TableCell
                      width={'30%'}
                      key={i1}
                      align={'center'}
                      padding={'none'}
                      style={{ borderWidth: 0, borderLeftWidth: 1, borderBottomWidth: 1, borderStyle: 'solid', borderColor: '#ccc' }}>
                      <Typography style={{ padding: 5, fontSize: 14 }}>{e1.grade || 'Chưa xong'}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      })}
    </div>
  );
};
