import { Button, Card } from '@material-ui/core';
import ModalConfirm from 'common/components/modals/ModalConfirm';
import Fetch from 'common/helpers/Fetch';
import Time from 'common/helpers/Time';
import ApiConst from 'consts/ApiConst';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GroupActionCreator from 'redux/actionCreators/GroupActionCreator';
import Utils from 'utils/Utils';

export default ({ item, isGroup }) => {
  const history = useHistory();
  const onClickDelete = () => {
    ModalConfirm.show({
      title: 'Xác nhận',
      content: `Bạn có chắc chắn muốn xóa "${item.name}" không?`,
      onClickOk: async () => {
        const res = await Fetch.post(ApiConst.GROUP.DELETE, { id: item.id }, true);
        if (res.status === 200) {
          GroupActionCreator.deleteGroup(item);
        }
      }
    });
  };
  const onClickArchive = () => {
    ModalConfirm.show({
      title: 'Xác nhận lưu trữ',
      content: `Bạn có chắc chắn muốn luư trữ "${item.name}" không?`,
      onClickOk: async () => {
        const res = await Fetch.post(ApiConst.GROUP.ARCHIVE, { id: item.id }, true);
        if (res.status === 200) {
          GroupActionCreator.archiveGroup(item);
        }
      }
    });
  };
  const onClickItem = () => {
    history.push({ pathname: pathname + (isGroup ? `/groups/${item.id}` : `/group/${item.id}`), data: item });
  };

  const pathname = Utils.viewingUserId ? '/' + Utils.viewingUserId : '';
  return (
    <div style={{}}>
      <Card style={{ position: 'relative', margin: 8, color: '#25282B', backgroundColor: 'white' }}>
        <Button style={{ display: 'flex', width: '100%' }} onClick={onClickItem}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20, textAlign: 'left' }}>
            <div style={{ display: 'flex', fontSize: 14, justifyContent: 'space-between' }}>
              <span style={{ fontSize: 18, fontWeight: '600' }}>{item.name}</span>
              {isGroup ? <span>{(item?.subGroup?.length || 0) + ' lớp học'}</span> : <span>{(item?.total_users || 0) + ' học viên'}</span>}
            </div>
            <div style={{ marginTop: 10 }}>
              Năm học:<span style={{ color: '#41A336', marginLeft: 10 }}>{Time.format(item.created_at, 'YYYY')}</span>
            </div>
            {!isGroup && (
              <div style={{ marginTop: 10 }}>
                Cấp độ:<span style={{ color: '#41A336', marginLeft: 10 }}>{item.course_name}</span>
              </div>
            )}
          </div>
        </Button>
        {item.showDelete && (
          <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
            <Button onClick={onClickArchive}>{'Lưu trữ'}</Button>
            <Button onClick={onClickDelete} style={{ color: 'red' }}>
              {'Xóa'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
