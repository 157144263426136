import Colors from 'assets/Colors';
import React, { PureComponent } from 'react';

export default class ItemInfo extends PureComponent {
  render() {
    const { icon, title, value, color } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
        {icon}
        <div style={{ marginLeft: 7 }}>{title}</div>
        <div style={{ marginLeft: 5, color: color ? color : Colors.primary }}>{value}</div>
      </div>
    );
  }
}
