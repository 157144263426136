import ArchiveGroupScreen from 'screens/ArchiveGroupScreen';
import CourseManagerScreen from 'screens/CourseManagerScreen';
import GroupDetailScreen from 'screens/GroupDetailScreen';
import GroupScreen from 'screens/GroupScreen';
import PartnerManagerScreen from 'screens/PartnerManagerScreen';
import ProgressScreen from 'screens/ProgressScreen';
import SubGroupScreen from 'screens/SubGroupScreen';
import TestingScreen from 'screens/TestingScreen';

export default {
  home: { path: '', component: ProgressScreen, title: 'Quản lý học tập' },
  progress: { path: 'progress', component: ProgressScreen, title: 'Quản lý học tập' },
  testing: { path: 'testing', component: TestingScreen, title: 'Kết quả bài kiểm tra' },
  groups: { path: 'groups', component: GroupScreen, title: 'Quản lý lớp học' },
  sgroups: { path: 'groups/:groupId?', component: SubGroupScreen, title: 'Quản lí lớp học' },
  group: { path: 'group/:groupId', component: GroupDetailScreen, title: 'Danh sách học viên' },
  archive: { path: 'archive', component: ArchiveGroupScreen, title: 'Lưu trữ' },

  // Admin view
  home1: { path: ':userId?', component: ProgressScreen, title: 'Quản lý học tập' },
  progress1: { path: ':userId?/progress', component: ProgressScreen, title: 'Quản lý học tập' },
  testing1: { path: ':userId?/testing', component: TestingScreen, title: 'Kết quả bài kiểm tra' },
  groups1: { path: ':userId?/groups', component: GroupScreen, title: 'Quản lí khóa học' },
  sgroups1: { path: ':userId?/groups/:groupId?', component: SubGroupScreen, title: 'Quản lí lớp học' },
  group1: { path: ':userId?/group/:groupId', component: GroupDetailScreen, title: 'Danh sách học viên' },
  archive1: { path: ':userId?/archive', component: ArchiveGroupScreen, title: 'Lưu trữ' },

  // Admin
  partnerManager: { path: 'admin/partner', component: PartnerManagerScreen, title: 'Quản lý doanh nghiệp' },
  courseManager: { path: 'admin/course', component: CourseManagerScreen, title: 'Quản lý khóa học' }
};
