import { Box, ButtonBase, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';

export const TypographyTitle = withStyles(() => createStyles({ root: { fontWeight: '700', fontSize: 14, color: '#25282B' } }))(Typography);
const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, color: '#52575C' } }))(Typography);

const ButtonPage = withStyles(() =>
  createStyles({ root: { borderWidth: 1, borderStyle: 'solid', margin: 5, borderRadius: 6, borderColor: '#E8E8E8', padding: 10 } })
)(ButtonBase);

export default ({ page, maxPage, onClickPrev, onClickNext }) => {
  return (
    <Box style={{ margin: 10 }}>
      <ButtonPage onClick={onClickPrev} disabled={page <= 1}>
        <IoIosArrowBack color={'#72C169'} size={20} />
      </ButtonPage>
      <ButtonPage onClick={onClickNext} disabled={page >= maxPage}>
        <IoIosArrowBack style={{ transform: 'scaleX(-1)' }} color={'#72C169'} size={20} />
      </ButtonPage>
      <TypographyContent style={{ marginLeft: 10 }} component={'span'}>
        {page + '/' + maxPage}
      </TypographyContent>
    </Box>
  );
};
