import { ButtonBase, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import Fetch from 'common/helpers/Fetch';
import Time from 'common/helpers/Time';
import PaginationView from 'components/views/PaginationView';
import ApiConst from 'consts/ApiConst';
import ScreenConst from 'consts/ScreenConst';
import React from 'react';
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleFill } from 'react-icons/ri';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';
import BaseScreen from 'screens/BaseScreen';
import FilterView from 'screens/BaseScreen/FilterView';
import Utils from 'utils/Utils';
import ItemTableInfo from './ItemTableInfo';

export const TypographyTitle = withStyles(() => createStyles({ root: { fontWeight: '700', fontSize: 14, color: '#25282B' } }))(Typography);

const ITEM_PER_PAGE = 50;
export class ProgressScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      checkAll: false,
      page: 1,
      maxPage: 1
    };
    this.filterList = [
      { title: 'Trình độ', key: 'level', default: 'Chưa có' },
      { title: 'Khoá - lớp', key: 'group_name', default: 'Chưa có' },
      { title: 'Thời gian học TB', key: 'learning_time', default: 0 },
      { title: 'Tiến độ', key: 'progress', default: 0 },
      { title: 'Trạng thái', key: 'status', default: 'Chưa đạt' }
    ];
    this.screen = ScreenConst.PROGRESS_SCREEN;
    this.users = [];
  }

  componentDidMount() {
    if (this.props.groups?.length > 0 && !this.group) {
      this.group = this.props.groups[0];
      this.getData(this.group);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.groups?.length > 0 && !this.group) {
      this.group = nextProps.groups[0];
      this.getData(this.group);
    }
    return this.state !== nextState;
  }

  getData = async (group) => {
    if (!group) group = this.group;
    this.group = group;
    const res = await Fetch.get(ApiConst.DMR.GET_USERS_PROGRESS, { groupId: group.id }, true);
    if (res.status === 200) {
      let users = res.data;
      let totalLearningTime = 0;
      let totalProgress = 0;
      users = users.map((e) => {
        const group = Utils.findGroupById(this.group.id);
        const totalDayFromStart = (Date.now() - Time.toMilliseconds(e.start_date)) / 1000 / 60 / 60 / 24;
        const progressExpected = e.watch_expired && (totalDayFromStart * 100) / e.watch_expired;
        e.isCompleteProgress = e.watch_expired && progressExpected <= e.progress;
        e.group_name = group?.name + (group?.sub?.name ? '-' + group?.sub?.name : '');
        totalProgress += e.progress || 0;
        totalLearningTime += e.learning_time || 0;
        return e;
      });
      users = users.sort((e1, e2) => e2.progress - e1.progress);
      this.users = users;
      HomeActionCreator.updatePartnerInfo({ totalLearningTime, totalProgress });
      this.updateDataForPage(1);
    }
  };

  getUserList = () => {
    return this.userSearchs ? this.userSearchs : this.users;
  };

  updateDataForPage = (page) => {
    this.page = page;
    const users = this.getUserList();
    let startIndex = (page - 1) * ITEM_PER_PAGE;
    if (startIndex < 0) startIndex = 0;
    const data = users.slice(startIndex, page * ITEM_PER_PAGE);
    this.setState({ data, maxPage: Math.ceil(users.length / ITEM_PER_PAGE) });
  };

  onClickCheckAll = () => {
    let { data, checkAll } = this.state;
    checkAll = !checkAll;
    data = data.map((e) => {
      e = { ...e, checked: checkAll };
      return e;
    });
    this.users = this.users.map((e) => ({ ...e, checked: checkAll }));
    this.setState({ data, checkAll });
  };

  onClickItem = (item) => {
    let { data, checkAll } = this.state;
    data = data.map((e) => {
      if (e.id === item.id) {
        e = { ...e, checked: !e.checked };
        if (!e.checked) checkAll = false;
      }
      return e;
    });
    this.setState({ data, checkAll });
  };

  onClickNextPage = () => {
    let { page } = this.state;
    if (page >= this.state.maxPage) return;
    page++;
    this.setState({ page });
    this.updateDataForPage(page);
  };

  onClickBackPage = () => {
    let { page } = this.state;
    if (page <= 1) return;
    page--;
    this.setState({ page });
    this.updateDataForPage(page);
  };

  onClickSortLearningTime = () => {
    const learningTimeAscending = !this.state.learningTimeAscending;
    this.setState({ learningTimeAscending });
    this.userSearchs = this.getUserList().sort((e1, e2) => {
      return learningTimeAscending ? e1.learning_time - e2.learning_time : e2.learning_time - e1.learning_time;
    });
    this.updateDataForPage(1);
  };

  onClickSortLearningProgress = () => {
    const progressAscending = !this.state.progressAscending;
    this.setState({ progressAscending });
    this.userSearchs = this.getUserList().sort((e1, e2) => {
      return progressAscending ? e1.progress - e2.progress : e2.progress - e1.progress;
    });
    this.updateDataForPage(1);
  };

  onSelectedGroup = (groupId) => {
    const group = Utils.findGroupById(groupId);
    this.setState({ data: [] });
    this.getData(group);
  };

  onSearch = (text) => {
    this.userSearchs = this.users.filter((e) => {
      return (
        e.name?.toLowerCase().indexOf(text?.toLowerCase()) >= 0 ||
        e.phone?.toLowerCase().indexOf(text?.toLowerCase()) >= 0 ||
        e.email?.toLowerCase().indexOf(text?.toLowerCase()) >= 0
      );
    });
    this.updateDataForPage(1);
  };

  renderItem = (item, i) => {
    return <ItemTableInfo key={i} item={item} onClickItem={this.onClickItem} index={i + (this.page - 1) * 10} />;
  };

  renderFilterView = () => {
    return (
      <FilterView
        searchHint={'Tìm kiếm theo tên, sđt, email'}
        onSelected={this.onSelectedGroup}
        onSearch={this.onSearch}
        data={this.state.data}
        exportFields={this.filterList}
      />
    );
  };

  renderTableHead = () => {
    const { checkAll } = this.state;
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell width={'8%'} align={'center'}>
            <ButtonBase style={{ display: 'inline-block' }} onClick={this.onClickCheckAll}>
              {checkAll ? <RiCheckboxMultipleFill size={20} /> : <RiCheckboxMultipleBlankLine size={20} />}
              <Typography style={{ fontSize: 9, padding: 0, lineHeight: 0.5, fontWeight: 'bold' }}>{'Chọn tất cả'}</Typography>
            </ButtonBase>
          </TableCell>
          <TableCell width={'10%'} padding={'none'} align={'center'}>
            <TypographyTitle>{'Xếp hạng'}</TypographyTitle>
          </TableCell>
          <TableCell width={'25%'} padding={'none'}>
            <TypographyTitle>{'Họ tên'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trình độ'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Lớp - Khóa'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'Thời gian học'}</TypographyTitle>
            {/* <ButtonBase onClick={this.onClickSortLearningTime}>
              <TypographyTitle>{'Thời gian học'}</TypographyTitle>
              {learningTimeAscending ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
            </ButtonBase> */}
          </TableCell>
          <TableCell width={'12%'} padding={'none'}>
            <TypographyTitle>{'Tiến độ'}</TypographyTitle>
            {/* <ButtonBase onClick={this.onClickSortLearningProgress}>
              <TypographyTitle>{'Tiến độ'}</TypographyTitle>
              {progressAscending ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
            </ButtonBase> */}
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trạng thái'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderContent = () => {
    const { data, page = 1, maxPage } = this.state;
    return (
      <div>
        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Table style={{}}>
            {this.renderTableHead()}
            <TableBody>{data.map(this.renderItem)}</TableBody>
          </Table>
          <PaginationView page={page} maxPage={maxPage} onClickPrev={this.onClickBackPage} onClickNext={this.onClickNextPage} />
        </Card>
      </div>
    );
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    groups: state.groupReducer.groups
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(ProgressScreen));
