import React from 'react';
import ItemGroup from 'components/views/ItemGroup';
import { withRouter } from 'react-router-dom';
import { GroupScreen } from 'screens/GroupScreen';
import { connect } from 'react-redux';

export class ArchiveGroupScreen extends GroupScreen {
  renderItem = (item, index) => {
    if (item.status === 1) return null;
    return <ItemGroup key={index} item={item} isGroup={this.isSchool} />;
  };

  renderButton = () => {
    return null;
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return { groups: state.groupReducer.groups, deleteGroup: state.groupReducer.deleteGroup };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(ArchiveGroupScreen));
