import Fetch from 'common/helpers/Fetch';
import Funcs from 'common/helpers/Funcs';
import ApiConst from 'consts/ApiConst';
import DataConst from 'consts/DataConst';
import { call, put, takeLatest } from 'redux-saga/effects';
import UserActionType from 'redux/types/UserActionType';
import Configs from 'utils/Configs';
import ModalLoading from 'common/components/modals/ModalLoading';

export function* watchUser() {
  yield takeLatest(UserActionType.LOGIN, login);
  yield takeLatest(UserActionType.AUTO_LOGIN, autoLogin);
}

function* login({ email, password, errorCallback }) {
  try {
    ModalLoading.show();
    let user = yield call(Fetch.post, ApiConst.USER.LOGIN, { email, password });
    if (user.status === 200) {
      user = user.data;
      Configs.token = user.token;
      localStorage.setItem(DataConst.USER_TOKEN, Configs.token);
      yield put({ type: UserActionType.UPDATE_USER_INFO, user });
    } else {
      errorCallback(user?.data?.error);
    }
    ModalLoading.hide();
  } catch (error) {
    Funcs.log('###error', error);
    errorCallback(error?.message);
  }
}

function* autoLogin({ callback }) {
  try {
    let user = yield call(Fetch.post, ApiConst.USER.AUTO_LOGIN, {}, true);
    if (user.status === 200) {
      user = user.data;
      yield put({ type: UserActionType.UPDATE_USER_INFO, user });
      callback(user);
      return;
    }
  } catch (error) {
    Funcs.log(error);
    yield put({ type: UserActionType.LOGIN_FAILED });
  }
  callback(null);
}
