import React from 'react';
import Modal from 'react-modal';
import { Ring } from 'react-awesome-spinners';

export default class ModalLoading extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalLoading.instance = this;
  }

  static show = () => {
    ModalLoading.instance?.setState({
      visible: true
    });
  };

  static hide = () => {
    ModalLoading.instance?.setState({
      visible: false
    });
  };

  render() {
    return (
      <Modal ariaHideApp={false} isOpen={this.state.visible} style={styles}>
        <Ring />
      </Modal>
    );
  }
}
const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none'
  },
  overlay: {
    zIndex: 100
  }
};
