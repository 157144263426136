import Button from '@material-ui/core/Button';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import styles from './styles.module.css';

const options = {
  colors: ['#41A336', '#FFD15A'],
  fill: {
    colors: ['rgba(255, 255, 255, 0.1)', 'rgba(132, 233, 116, 0.5)'],
    type: 'gradient',
    gradient: { shadeIntensity: 1, opacityFrom: 0.5, opacityTo: 0.7, stops: [0, 95, 100] }
  },
  chart: { toolbar: { show: false }, zoom: { enabled: false } },
  stroke: { width: 2, curve: 'smooth' },
  dataLabels: { enabled: false },
  legend: { position: 'top', markers: { width: 17, height: 2 }, itemMargin: { horizontal: 15 } },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return '<div>' + series[seriesIndex][dataPointIndex] + '</div>';
    }
  },
  xaxis: {
    tooltip: { enabled: false },
    axisBorder: { show: true, color: '#78909C', height: 1, offsetY: -1 },
    axisTicks: { color: '#78909C' },
    crosshairs: { show: true, stroke: { color: '#000000', width: 1, dashArray: 3, opacity: 10 } }
  },
  grid: { show: true, borderColor: '#78909C', strokeDashArray: 3, yaxis: { lines: { show: true } }, padding: { left: 10 } }
};

export default ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleClick = () => setOpen(true);
  const testingResults = user.testing_result;
  const series = [
    // { name: 'Điểm TB Lớp Kế Toán', type: 'area', data: [] },
    { name: `Điểm của ${user.name}`, type: 'area', data: [] }
  ];
  // series[0].data = testingResults?.map((e) => e.average);
  series[0].data = testingResults?.map((e) => e.grade);
  options.labels = testingResults?.map((e, i) => 'Đợt ' + (i + 1));

  return (
    <div className={styles.boxCharts}>
      <div className={styles.viewTitle}>
        <span className={styles.titleChart}>Điểm kiểm tra theo đợt</span>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            className={styles.buttonCourse}
            style={{ padding: 0 }}
            onClick={handleClick}></Button>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="area" height={250} width={'100%'} />
    </div>
  );
};
