import { combineReducers } from 'redux';
import { homeReducer } from './HomeReducer';
import { groupReducer } from './GroupReducer';
import { userReducer } from './UserReducer';

const appReducer = combineReducers({
  homeReducer,
  userReducer,
  groupReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
