import HomeActionType from 'redux/types/HomeActionType';

const initialState = {};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomeActionType.GET_PARTNER_INFO_SUCCESS:
      return { ...state, partnerInfo: { ...state.partnerInfo, ...action.partnerInfo } };
    case HomeActionType.GET_LIST_COURSE_SUCCESS:
      return { ...state, courses: action.courses };
    case HomeActionType.UPDATE_HEADER_TITLE:
      return { ...state, headerTitle: action.headerTitle };
    case HomeActionType.UPDATE_USER_INFO:
      return { ...state, user: action.user };
    default:
      return state;
  }
};
