import ScreenConst from 'consts/ScreenConst';
import React, { Component } from 'react';
import Utils from 'utils/Utils';
import FilterView from './FilterView';
import PartnerInfoView from './PartnerInfoView';

export default class BaseScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.screen = ScreenConst.BASE_SCREEN;
    Utils.viewingUserId = this.props.match?.params?.userId;
  }

  renderContent = () => {
    return null;
  };

  renderBoxInfo = () => {
    return <PartnerInfoView screen={this.screen} />;
  };

  renderFilterView = () => {
    return <FilterView data={this.users} />;
  };

  renderModal = () => {
    return null;
  };

  render() {
    return (
      <div style={{ padding: 32 }}>
        {this.renderBoxInfo()}
        {this.renderFilterView()}
        {this.renderContent()}
        {this.renderModal()}
      </div>
    );
  }
}
