import RouteConst from 'consts/RouteConst';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ItemMenu from './ItemMenu';
import styles from './styles.module.css';

const menuList = [RouteConst.progress, RouteConst.groups, RouteConst.archive];
const adminMenuList = [RouteConst.partnerManager, RouteConst.courseManager];

export default () => {
  const user = useSelector((state) => state.userReducer.user);
  const [currentIndex, setCurrentIndex] = useState(0);
  let menu = menuList;
  const location = useLocation();

  const onClickItem = (currentIndex) => {
    setCurrentIndex(currentIndex);
  };

  // Menu admin
  if (user?.role === 0 && location.pathname.indexOf('/admin') >= 0) {
    menu = adminMenuList;
  }

  // Init
  useEffect(() => {
    // Check path when reload browser
    const pathname = location.pathname.replace('/', '');
    for (let i = 0; i < menu.length; i += 1) {
      const data = menu[i];
      if (data.path === pathname) {
        onClickItem(i);
        break;
      }
    }

    // Exception
    if (pathname.indexOf('group/') === 0) {
      onClickItem(1);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Link to="/progress" onClick={() => onClickItem(0, 0)}>
        <img className={styles.logo} src={'https://dungmori.com/assets/img/new_home/header_logo.png'} />
      </Link>
      <div className={styles.label}>E-learning Management</div>
      {menu.map((item, index) => {
        return <ItemMenu key={index.toString()} item={item} index={index} parentIndex={index} currentIndex={currentIndex} onClickItem={onClickItem} />;
      })}
    </div>
  );
};
