import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import Utils from 'utils/Utils';

export default ({ item, index, currentIndex, onClickItem }) => {
  const onClick = () => {
    if (onClickItem) onClickItem(index);
  };
  const pathname = Utils.viewingUserId ? '/' + Utils.viewingUserId + '/' + item?.path : '/' + item?.path;
  return (
    <div>
      <Link to={{ pathname, data: item }} style={{ color: currentIndex === index ? '#41A336' : '#52575C' }} className={styles.titleItem} onClick={onClick}>
        {item?.title}
      </Link>
    </div>
  );
};
