export default {
  ADD_GROUP: 'ADD_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  ARCHIVE_GROUP: 'ARCHIVE_GROUP',
  GET_LIST_GROUP: 'GET_LIST_GROUP',
  GET_LIST_GROUP_SUCCESS: 'GET_LIST_GROUP_SUCCESS',
  GET_LIST_GROUP_FAILED: 'GET_LIST_GROUP_FAILED',
  GET_LIST_GROUP_USER: 'GET_LIST_GROUP_USER',
  GET_LIST_GROUP_USER_SUCCESS: 'GET_LIST_GROUP_USER_SUCCESS'
};
