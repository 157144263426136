import { ButtonBase } from '@material-ui/core';
import React from 'react';
import Modal from 'react-modal';
import styles from './styles.module.css';

const style = {
  button: {
    padding: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    color: 'white',
    fontWeight: 'bold',
    margin: 5,
    borderRadius: 10,
    backgroundColor: 'rgb(24, 87, 24)',
    paddingLeft: 15,
    paddingRight: 15
  }
};

export default class ModalConfirm extends React.Component {
  static instance = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    ModalConfirm.instance = this;
  }

  static show = ({ onClickOk, title, content }) => {
    ModalConfirm.instance?.setState({
      visible: true,
      data: { onClickOk, title, content }
    });
  };

  static hide = () => {
    ModalConfirm.instance?.setState({
      visible: false
    });
  };

  render() {
    const { data } = this.state;
    return (
      <Modal
        onRequestClose={ModalConfirm.hide}
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { maxWidth: 400, maxHeight: 180, margin: 'auto', padding: 10, display: 'flex', flexDirection: 'column' }
        }}>
        <div className={styles.header}>
          <div className={styles.title}>{data?.title}</div>
        </div>
        <div className={styles.contentContainer}>{data?.content}</div>
        <div className={styles.containerBottom}>
          <ButtonBase
            style={style.button}
            onClick={() => {
              ModalConfirm.hide();
              if (data?.onClickOk) data?.onClickOk();
            }}>
            Đồng ý
          </ButtonBase>
          <ButtonBase style={style.button} onClick={ModalConfirm.hide}>
            Hủy
          </ButtonBase>
        </div>
      </Modal>
    );
  }
}
