import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import ScreenConst from 'consts/ScreenConst';

export default ({ screen }) => {
  const data = [];
  const [partnerInfo, groups] = useSelector((state) => [state.homeReducer.partnerInfo, state.groupReducer.groups]);
  if (!partnerInfo) return null;
  if (!partnerInfo?.totalProgress) partnerInfo.totalProgress = 0;
  if (!partnerInfo?.totalGrade) partnerInfo.totalGrade = 0;
  if (!partnerInfo?.totalLearningTime) partnerInfo.totalLearningTime = 0;
  if (groups?.length > 0) data.push({ title: 'Tổng số lớp học', value: groups?.length });
  if (partnerInfo?.total_dmr_user) data.push({ title: 'Tổng số học viên', value: partnerInfo.total_dmr_user || 0 });
  data.push({ title: 'Tiến độ trung bình', value: Math.floor(partnerInfo.totalProgress / (partnerInfo.total_dmr_user || 1)) + '%' });
  if (screen === ScreenConst.TESTING_SCREEN) {
    data.push({ title: 'Điểm trung bình', value: Number(partnerInfo.totalGrade / (partnerInfo.total_dmr_user || 1)).toFixed(2) });
  }
  if (screen === ScreenConst.PROGRESS_SCREEN) {
    data.push({ title: 'Thời gian trung bình', value: Number(partnerInfo.totalLearningTime / (partnerInfo.total_dmr_user || 1)).toFixed(2) + ' giờ' });
  }
  return (
    <Box sx={{ flexGrow: 1 }} mb={4}>
      <Grid container spacing={2}>
        {data.map((e, i) => (
          <Grid item key={i} sm={3} xs={12}>
            <Card>
              <CardContent>
                <Typography component={'p'} variant={'body2'} gutterBottom>
                  {e.title}
                </Typography>
                <Typography component={'span'} variant={'h6'} color={'primary'}>
                  {e.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
