import UserActionType from 'redux/types/UserActionType';
import Configs from 'utils/Configs';

const initialState = {};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionType.UPDATE_USER_INFO:
      Configs.user = action.user;
      return { ...state, user: action.user };
    case UserActionType.ADD_USER:
      return { ...state, newUser: action.user };
    case UserActionType.REMOVE_USER:
      return { ...state, userRemoved: action.user };
    case UserActionType.UPDATE_USER:
      return { ...state, userUpdated: action.user };
    default:
      return state;
  }
};
