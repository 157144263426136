import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Colors from 'assets/Colors';
import React from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Store from 'redux/Store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: { primary: { main: Colors.primary } }
});

const options = {
  position: positions.TOP_CENTER,
  timeout: 2000,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 1000
  }
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </AlertProvider>,
  document.getElementById('root')
);

reportWebVitals();
