const moment = require('moment');
require('moment/locale/vi');
moment.locale('vi');

export default {
  format: (str, formatStr = 'YYYY-MM-DD HH:mm:ss') => {
    var time = moment(str).format(formatStr);
    return time;
  },

  fromNow: (str, formatStr = '') => {
    return moment(str, formatStr).fromNow();
  },

  toMilliseconds: (str, formatStr = '') => {
    return moment(str, formatStr).valueOf();
  },

  toObject: (time) => {
    if (!time) time = Date.now();
    let timeObject = new Date(time);
    let month = timeObject.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = timeObject.getDate();
    if (date < 10) date = '0' + date;
    return {
      date,
      month,
      year: timeObject.getFullYear()
    };
  },

  subDay: (date1, date2) => {
    var a = moment(date1);
    var b = moment(date2);
    return a.diff(b, 'days');
  },

  subtract: (date, amount, unit = 'day', format = 'YYYY-MM-DD'): string => {
    var a = moment(date, format);
    a = a.subtract(amount, unit);
    return a.format(format);
  },

  nextBirthday: (timeObj: { date: number, month: number | string, year: number }) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month > parseInt(timeObj.month) || (day > timeObj.date && month === parseInt(timeObj.month))) year += 1;
    return {
      year,
      month: timeObj.month,
      date: timeObj.date
    };
  },

  formatSecondToTime: (totalSecond) => {
    var timeStr = '00:00:00';
    var second = totalSecond % 60;
    var hour = Math.floor(totalSecond / 3600);
    const hourRedu = totalSecond / 3600 - hour;
    var min = Math.floor(Math.round(hourRedu * 3600) / 60);
    if (second < 60) {
      second = parseInt(second);
    }
    if (second < 10) {
      second = '0' + parseInt(second);
    }
    if (min < 10) {
      min = '0' + min;
    }
    if (hour < 10) {
      hour = '0' + parseInt(hour);
    }
    timeStr = hour + ':' + min + ':' + second;
    return timeStr;
  }
};
