import React from 'react';
import { BsCheck } from 'react-icons/bs';
import styles from './styles.module.css';

export default () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box}>
          <img className={styles.logo} src={'https://dungmori.com/assets/img/new_home/header_logo.png'} />
          <span className={styles.textConfirm}>Gửi đặt lại mật khẩu thành công</span>
          <div className={styles.viewCheck}>
            <BsCheck size={30} color={'#41A336'} />
          </div>
          <p
            style={{
              fontSize: 13,
              color: 'rgba(0, 0, 0, 0.3)',
              marginTop: 30
            }}>
            Check email để đặt lại mật khẩu mới
          </p>
        </div>
      </div>
    </div>
  );
};
