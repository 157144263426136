import axios from 'axios';
import UrlConst from 'consts/UrlConst';
import Configs from 'utils/Configs';
import Funcs from './Funcs';

const Platform = { OS: 'web' };

const Status = {
  INTERNAL_SERVER_ERROR: 500,
  NOT_EXITS: 400,
  UNAUTHORIZED: 401,
  SUCCESS: 200,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  ALREADY_EXIST: 409,
  PHONE_DOES_NOT_EXIT: 426,
  CODE_DOES_NOT_EXIT: 427,
  PHONE_ALREADY_LOGIN_SOCIAL: 428,
  POINT_NOT_ENOUGH: 429,
  DATE_EXPIRY: 434,
  NUMBER_EXPIRED: 432,
  NOT_ENTER_YOUR_CODE: 430,
  ERROR_MULTI_CODE: 435,
  GENERIC_ERROR: 1000,
  NETWORK_ERROR: 1001
};

const baseURL = UrlConst.API;
const instance = axios.create({
  baseURL,
  timeout: 10000
});

async function parseErrorResult(err, config) {
  var result = err && err.response;
  if (!result) {
    result = {
      config,
      status: Status.NETWORK_ERROR,
      message: 'Network error'
    };
  }
  // AuthenticateActionCreator.verifyTokenFailed();
  Funcs.log('ERROR', result);
  return result;
}

async function parseSuccessResult(res) {
  const { url, headers, params, method } = res.config;
  var result = {
    data: res.data,
    status: res.status,
    config: { url, headers, params, method }
  };
  Funcs.log('SUCCESS', result);
  return result;
}

async function getHeader(url) {
  try {
    Funcs.log('GET HEAD', baseURL + url);
    var res = await instance.head(url, {
      timeout: 3000
    });
    return res.headers;
  } catch (err) {
    Funcs.log(err);
    return err;
  }
}

async function getFileSize(url) {
  try {
    Funcs.log('GET HEAD', baseURL + url);
    var res = await instance.head(url, {
      timeout: 3000
    });
    Funcs.log('SUCCESS', res);
    let size = res.headers['content-length'];
    size = size / 1000 / 1000;
    size = Math.round(size * 100) / 100;
    return size;
  } catch (err) {
    Funcs.log(err);
    return '';
  }
}

const isStaticURL = (url) => url?.indexOf('http://') === 0 || url?.indexOf('https://') === 0;

async function get(url, data, auth, cancelRequest) {
  let headers = {};
  if (typeof auth === 'object') {
    headers = auth;
  } else if (auth) {
    headers = {
      token: Configs.token || Configs.user?.token
    };
  }
  headers.platform = Platform.OS;
  try {
    const isStaticUrl = isStaticURL(url);
    let fullUrl = isStaticUrl ? url : baseURL + url;
    Funcs.log('GET', fullUrl, data, headers);
    var res = await instance.get(url, {
      params: {
        ...data
      },
      baseURL: isStaticUrl ? '' : baseURL,
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        if (cancelRequest) cancelRequest.cancel = c;
      })
    });
    return parseSuccessResult(res);
  } catch (err) {
    return parseErrorResult(err, { url, data, headers });
  }
}

async function post(url, data, auth, cancelRequest) {
  let headers = {};
  if (typeof auth === 'object') {
    headers = auth;
  } else if (auth) {
    headers = {
      token: Configs.token || Configs.user?.token
    };
  }
  headers.platform = Platform.OS;
  try {
    const isStaticUrl = isStaticURL(url);
    let fullUrl = isStaticUrl ? url : baseURL + url;
    Funcs.log('POST', fullUrl, data, headers);
    var res = await instance.post(
      url,
      { ...data },
      {
        baseURL: isStaticUrl ? '' : baseURL,
        headers,
        cancelToken: new axios.CancelToken(function executor(c) {
          if (cancelRequest) cancelRequest.cancel = c;
        })
      }
    );
    return parseSuccessResult(res);
  } catch (err) {
    return parseErrorResult(err, { url, data, headers });
  }
}

async function postForm(url, data, auth, cancelRequest) {
  // Form data
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((val) => formData.append(`${key}`, val));
    }
    formData.append(key, value);
  });
  let headers = {};
  if (typeof auth === 'object') {
    headers = auth;
  } else if (auth) {
    headers = {
      token: Configs.token || Configs.user?.token
    };
  }
  headers.platform = Platform.OS;
  try {
    Funcs.log('POST', baseURL + url, data, headers);
    var res = await instance.post(url, formData, {
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        if (cancelRequest) cancelRequest.cancel = c;
      })
    });
    return parseSuccessResult(res);
  } catch (err) {
    return parseErrorResult(err, { url, data, headers });
  }
}

export default {
  get,
  post,
  postForm,
  getHeader,
  getFileSize,
  Status
};
