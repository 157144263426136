import { ButtonBase, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';
import AppConst from 'consts/AppConst';
import ScreenConst from 'consts/ScreenConst';
import React from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleFill } from 'react-icons/ri';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';
import { ProgressScreen, TypographyTitle } from 'screens/ProgressScreen';
import Utils from 'utils/Utils';
import ItemTestingInfo from './ItemTestingInfo';

const gradeMark = [20, 30, 50, 60];
const states = ['Yếu', 'Trung bình', 'Khá', 'Giỏi'];
const stateColors = ['#D73A3A', '#A38B36', '#41A336', '#41A336'];

function getGradeState(grade) {
  if (!grade) grade = 0;
  let stateIndex = 0;
  for (let i = 0; i < gradeMark.length; i += 1) {
    if (grade < gradeMark[i]) {
      stateIndex = i;
      break;
    }
  }
  return { state: states[stateIndex], color: stateColors[stateIndex] };
}

class TestingScreen extends ProgressScreen {
  constructor(props) {
    super(props);
    this.screen = ScreenConst.TESTING_SCREEN;
    this.filterList = [
      { title: 'Trình độ', key: 'level', default: 'Chưa có' },
      { title: 'Khoá - lớp', key: 'group_name', default: 'Chưa có' },
      { title: 'Bài đã làm', key: 'total', default: 0 },
      { title: 'Điểm TB', key: 'grade', default: 0 },
      { title: 'Trạng thái', key: 'gradeStateName', default: 'Yếu' }
    ];
  }

  getData = async (group) => {
    if (!group) group = this.group;
    this.group = group;
    const res = await Fetch.get(ApiConst.DMR.GET_USERS_TESTING, { groupId: group.id }, true);
    if (res.status === 200) {
      const userGroup = Utils.findGroupById(this.group.id);
      let users = res.data;
      let totalGrade = 0;
      users = users.map((e) => {
        e.level = userGroup.level;
        e.gradeState = getGradeState(e.grade);
        e.gradeStateName = e.gradeState.state;
        e.group_name = group?.name + (group?.sub?.name ? '-' + group?.sub?.name : '');
        totalGrade += e.grade || 0;
        return e;
      });
      HomeActionCreator.updatePartnerInfo({ totalGrade });
      this.users = users;
      this.updateDataForPage(1);
    }
  };

  onClickSortTotalTest = () => {
    const totalTestAscending = !this.state.totalTestAscending;
    this.setState({ totalTestAscending });
    this.userSearchs = this.getUserList().sort((e1, e2) => {
      if (!e1.total) e1.total = 0;
      if (!e2.total) e2.total = 0;
      return totalTestAscending ? e1.total - e2.total : e2.total - e1.total;
    });
    this.updateDataForPage(1);
  };

  onClickSortGrade = () => {
    const gradeAscending = !this.state.gradeAscending;
    this.setState({ gradeAscending });
    this.userSearchs = this.getUserList().sort((e1, e2) => {
      if (!e1.grade) e1.grade = 0;
      if (!e2.grade) e2.grade = 0;
      return gradeAscending ? e1.grade - e2.grade : e2.grade - e1.grade;
    });
    this.updateDataForPage(1);
  };

  renderItem = (item, i) => {
    return <ItemTestingInfo type={AppConst.TYPE_USER_INFO.TESTING} key={i} item={item} onClickItem={this.onClickItem} />;
  };

  renderTableHead = () => {
    const { checkAll, totalTestAscending, gradeAscending } = this.state;
    return (
      <TableHead style={{}}>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell width={'10%'} align={'center'}>
            <ButtonBase style={{ display: 'inline-block' }} onClick={this.onClickCheckAll}>
              {checkAll ? <RiCheckboxMultipleFill size={20} /> : <RiCheckboxMultipleBlankLine size={20} />}
              <Typography style={{ fontSize: 9, padding: 0, lineHeight: 0.5, fontWeight: 'bold' }}>{'Chọn tất cả'}</Typography>
            </ButtonBase>
          </TableCell>
          <TableCell width={'25%'} padding={'none'}>
            <TypographyTitle>{'Họ tên'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trình độ'}</TypographyTitle>
          </TableCell>
          <TableCell width={'20%'} padding={'none'}>
            <TypographyTitle>{'Lớp - Khóa'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <ButtonBase onClick={this.onClickSortTotalTest}>
              <TypographyTitle>{'Bài đã làm'}</TypographyTitle>
              {totalTestAscending ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
            </ButtonBase>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <ButtonBase onClick={this.onClickSortGrade}>
              <TypographyTitle>{'Điểm TB'}</TypographyTitle>
              {gradeAscending ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
            </ButtonBase>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trạng thái'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    groups: state.groupReducer.groups
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(TestingScreen));
