import Colors from 'assets/Colors';
import Time from 'common/helpers/Time';
import React, { PureComponent } from 'react';
import { BiCheckDouble } from 'react-icons/bi';
import { FaCalendarCheck } from 'react-icons/fa';
import { RiTimeFill } from 'react-icons/ri';
import Utils from 'utils/Utils';
import { default as ItemInfo, default as ItemOverview } from '../../../views/ItemInfo';
import styles from './styles.module.css';

export default class LearningProgress extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listTab: [{ id: 0, title: 'Tiến độ học tập' }],
      currentIndex: 0,
      widthLabel: 0,
      widthContainer: 0
    };
  }

  componentDidMount() {
    this.setState({
      widthContainer: this.Container.offsetWidth,
      widthLabel: this.ContentLabel.offsetWidth
    });
  }

  onClickChangeTab = (item, index) => this.setState({ currentIndex: index });

  renderContent = () => {
    const { data } = this.props;
    if (!data) return null;
    const testingResult = data.testing_result || [];
    const { widthLabel, widthContainer } = this.state;
    const progress = data.progress || 0;
    const positions = progress === 0 ? 0 : progress - ((widthLabel / widthContainer) * 100) / 1.8;
    const dayPerStage = data?.watch_expired / 3;
    const timeStage2 = Time.toMilliseconds(data?.start_date) + 2 * dayPerStage * 24 * 60 * 60 * 1000;
    const timeStage3 = Time.toMilliseconds(data?.start_date) + 3 * dayPerStage * 24 * 60 * 60 * 1000;

    // Score
    let score = 0;
    for (let i = 0; i < testingResult.length; i += 1) {
      score += testingResult[i].grade;
    }
    score = Number(score / testingResult.length).toFixed(2);
    const gradeState = Utils.getGradeState(score);

    return (
      <div>
        <center>
          <div style={{ fontWeight: 'bold', fontSize: 21, marginTop: 26 }}>Tiến độ học tập</div>
        </center>
        <div style={{ marginTop: 40 }}>
          <div className={styles.viewResoultProgress} ref={(el) => (this.Container = el)}>
            <div className={styles.textProgress} style={{ left: `${positions}%` }} ref={(el) => (this.ContentLabel = el)}>
              {`${progress}%`}
            </div>
          </div>
          <div className={styles.boxProgress}>
            <div className={styles.viewProgress}>
              <div className={styles.valueProgress} style={{ width: `${progress}%` }} />
            </div>
            <div className={styles.viewTitleLeg}>
              <div className={styles.viewSteper}>
                <div className={styles.viewLeg} style={{ alignItems: 'flex-start' }}>
                  <div className={styles.leg} style={{ backgroundColor: progress > 0 ? Colors.green : '#C4C4C4' }} />
                  <div className={styles.titLeg}>Chặng 1</div>
                </div>
                <div className={styles.viewLeg} style={{ alignItems: 'flex-end' }}>
                  <div className={styles.leg} style={{ backgroundColor: progress === 100 ? Colors.green : '#C4C4C4' }} />
                  <div className={styles.titLeg}>Chặng 3</div>
                  <div className={styles.textDuration}>Hạn {Time.format(timeStage3, 'DD/MM/YY')}</div>
                </div>
              </div>
              <div className={styles.viewLeg} style={{ alignItems: 'center' }}>
                <div className={styles.leg} style={{ backgroundColor: progress >= 50 ? Colors.green : '#C4C4C4' }} />
                <div className={styles.titLeg}>Chặng 2 </div>
                <div className={styles.textDuration}>Hạn tới {Time.format(timeStage2, 'DD/MM/YY')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overview}>
          <div className={styles.viewProgressResult}>
            <div style={{ marginBottom: 12 }}>Tiến độ</div>
            <div style={{ fontSize: 30, fontWeight: '700', color: data.isCompleteProgress ? '#41A336' : '#D73A3A' }}>{`${progress}%`}</div>
            <div className={styles.makeProgress}>{data.isCompleteProgress ? 'Đạt tiến độ' : 'Chưa đạt tiến độ'}</div>
          </div>
          <div className={styles.viewProgressResult}>
            <div style={{ marginBottom: 12 }}>Điểm kiểm tra TB</div>
            <div style={{ fontSize: 30, fontWeight: '700', color: Colors.green }}>{isNaN(score) ? 0 : score}</div>
            <div className={styles.makeProgress} style={{ backgroundColor: gradeState?.color }}>
              {gradeState?.state}
            </div>
          </div>
          <div className={styles.viewInfo}>
            <div style={{ marginTop: 10, marginBottom: 10 }}>Ngày kích hoạt: {Time.format(data.start_date, 'DD/MM/YYYY')} </div>
            <ItemOverview icon={<FaCalendarCheck size={20} color={Colors.green} />} title={'Hoàn thành:'} value={(data.total_finish_video || 0) + ' videos'} />
            <ItemOverview icon={<BiCheckDouble size={25} color={Colors.green} />} title={'Hôm nay:'} value={(data.total_finish_video_today || 0) + ' videos'} />
            <ItemOverview icon={<RiTimeFill size={22} color={Colors.green} />} title={'Thời gian đã học:'} value={(data.learning_time || 0) + ' giờ'} />
          </div>
          <div className={styles.viewInfo}>
            <ItemInfo icon={<BiCheckDouble size={20} color={Colors.green} />} title={'Tổng số bài đã làm:'} value={testingResult.length} />
            <ItemInfo icon={<RiTimeFill size={19} color={Colors.green} />} title={'Trạng thái: '} value={gradeState?.state} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <div className={styles.container}>{this.renderContent()}</div>;
  }
}
