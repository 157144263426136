import { ButtonBase, MenuItem, Select, Typography } from '@material-ui/core';
import Colors from 'assets/Colors';
import Fetch from 'common/helpers/Fetch';
import Funcs from 'common/helpers/Funcs';
import Time from 'common/helpers/Time';
import Button from 'components/views/Button';
import ApiConst from 'consts/ApiConst';
import Consts from 'consts/Consts';
import React from 'react';
import { withAlert } from 'react-alert';
import { BiUserCircle } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import UserActionCreator from 'redux/actionCreators/UserActionCreator';
import styles from './styles.module.css';

class ModalAddPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      partnerIndex: 1,
      isShowSelect: false
    };
  }

  show = (data) => {
    this.setState({ visible: true, data }, async () => {
      if (!data?.id) return;
      await Funcs.delay(500);
      this.inputName.value = data.name;
      this.inputPhone.value = data.phone || '';
      this.inputEmail.value = data.email || '';
      this.inputPartnerId.value = data.partner_id || '';
      this.setState({ partnerIndex: data.role });
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onClickSubmit = async () => {
    const { data } = this.state;
    const name = this.inputName.value;
    const phone = this.inputPhone.value;
    const email = this.inputEmail.value;
    const password = this.inputPass.value;
    const partnerId = this.inputPartnerId.value;
    const role = Consts.USER_ROLES[this.state.partnerIndex].value;
    if (!name || name?.length < 2) {
      this.props.alert.error('Tên doanh nghiệp không hợp lệ');
      return false;
    }
    if (!phone || phone?.length < 2) {
      this.props.alert.error('Số điện thoại không hợp lệ');
      return false;
    }
    if (!email || email?.length < 2) {
      this.props.alert.error('Email không hợp lệ');
      return false;
    }
    // if (!password || password?.length < 5) {
    //   this.props.alert.error('Mật khẩu phải lớn hơn 5 ký tự');
    //   return false;
    // }
    if (!partnerId || partnerId?.length < 1) {
      this.props.alert.error('Mời bạn nhập mã doanh nghiệp');
      return false;
    }
    const newInfo = { id: data.id, name, email, password, phone, partnerId, role };
    const res = await Fetch.post(data?.id ? ApiConst.USER.UPDATE : ApiConst.USER.REGISTER, newInfo, true);
    if (res.status === 200) {
      data?.id ? UserActionCreator.updateUser({ ...data, ...newInfo, partner_id: partnerId }) : UserActionCreator.addUser(res.data);
      this.hide();
      this.props.alert.success(data?.id ? 'Cập nhật thành công' : 'Thêm tài khoản thành công');
    } else {
      this.props.alert.error('Thất bại');
    }
  };

  onSelectClose = () => {
    this.setState({ isShowSelect: false });
  };

  onSelectOpen = () => {
    this.setState({ isShowSelect: true });
  };

  onSelectChange = (event) => {
    const index = parseInt(event.target.value);
    this.setState({ partnerIndex: index });
  };

  render() {
    const { visible, partnerIndex, isShowSelect, data } = this.state;
    const styleText = { marginLeft: 20 };
    return (
      <Modal
        onRequestClose={this.hide}
        ariaHideApp={false}
        isOpen={visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { width: 600, height: 450, padding: 20, margin: 'auto', borderRadius: 0 }
        }}>
        <div className={styles.boxModal}>
          <Button style={{ position: 'absolute', right: 0, top: 0 }} onClick={this.hide}>
            <MdClose size={24} />
          </Button>
          <div className={styles.content}>
            <span className={styles.title}>{data?.id ? 'Sửa tài khoản' : 'Thêm tài khoản đối tác'}</span>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Ngày bắt đầu: ' + Time.format(data?.created_at || new Date(), 'DD/MM/YYYY')}:</span>
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Tên'}:</span>
              <input ref={(ref) => (this.inputName = ref)} className={styles.inputContainer} type="text" required="" />
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Email'}:</span>
              <input ref={(ref) => (this.inputEmail = ref)} className={styles.inputContainer} type="text" required="" />
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Mật khẩu'}:</span>
              <input ref={(ref) => (this.inputPass = ref)} className={styles.inputContainer} type="password" required="" />
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Mã đối tác'}:</span>
              <input ref={(ref) => (this.inputPartnerId = ref)} className={styles.inputContainer} type="text" required="" />
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>{'Số điện thoại'}:</span>
              <input ref={(ref) => (this.inputPhone = ref)} className={styles.inputContainer} type="text" required="" />
            </div>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={styleText}>Phân loại:</span>
              {Consts.USER_ROLES?.length > 0 && (
                <Select
                  style={{ flex: 1, marginLeft: 10 }}
                  disableUnderline
                  open={isShowSelect}
                  onClose={this.onSelectClose}
                  onOpen={this.onSelectOpen}
                  onChange={this.onSelectChange}
                  value={Consts.USER_ROLES[partnerIndex].name}
                  renderValue={() => <Typography style={{ color: Colors.green, fontWeight: '600' }}>{Consts.USER_ROLES[partnerIndex].name}</Typography>}>
                  {Consts.USER_ROLES?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <ButtonBase className={styles.btnSuccess} style={{ padding: 0, backgroundColor: Colors.green, marginTop: 20 }} onClick={this.onClickSubmit}>
              Xác nhận
            </ButtonBase>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withAlert()(ModalAddPartner);
