import { ButtonBase, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.css';

export default () => {
  const alert = useAlert();
  const inputUserName = useRef(null);
  const history = useHistory();

  const onClick = async () => {
    if (!inputUserName.current.value) {
      alert.error('Mời Bạn nhập đủ thông tin');
      return;
    }
    history.push({ pathname: '/confirm-password' });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box}>
          <img className={styles.logo} src={'https://dungmori.com/assets/img/new_home/header_logo.png'} />
          <span className={styles.textCenter}>Đặt lại mật khẩu</span>
          <input className={styles.inputContainer} ref={inputUserName} type="text" placeholder="Email của doanh nghiệp" />
          <ButtonBase
            onClick={onClick}
            className={styles.btn}
            style={{
              backgroundColor: '#41A336',
              borderRadius: 5,
              marginTop: 25
            }}>
            <Typography style={{ color: '#fff', fontSize: 14, textDecorationLine: 'none' }}>Xác nhận</Typography>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};
