import { Avatar, CardHeader } from '@material-ui/core';
import ModalUserInfo from 'components/modals/ModalUserInfo';
import UrlConst from 'consts/UrlConst';
import React from 'react';

export default ({ user = {}, type, disabled }) => {
  const onClick = () => {
    if (disabled) return;
    ModalUserInfo.show(user, type);
  };
  return (
    <CardHeader
      onClick={onClick}
      style={{ padding: 0, paddingTop: 10, paddingBottom: 10, color: '#52575C' }}
      avatar={
        <Avatar style={{ width: 32, height: 32 }} src={user.avatar ? UrlConst.DUNGMORI_CDN + 'avatar/small/' + user.avatar : 'images/default_avatar.png'} />
      }
      title={user.name}
    />
  );
};
