import { ButtonBase, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { TypographyTitle } from 'components/views/PaginationView';
import React from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';
import BaseScreen from 'screens/BaseScreen';
import Fetch from 'common/helpers/Fetch';
import ApiConst from 'consts/ApiConst';

export const TypographyContent = withStyles(() => createStyles({ root: { fontSize: 14, padding: 10 } }))(Typography);

export class CourseManagerScreen extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = {
      courses: props.courses || []
    };
  }

  async componentDidMount() {
    HomeActionCreator.getListCourse();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.courses?.length > 0 && nextProps.courses != this.props.courses) {
      this.setState({ courses: nextProps.courses });
    }
    return this.state !== nextState;
  }

  onClickItem = (item) => () => {
    let { courses } = this.state;
    courses = courses.map((e) => {
      if (item.id === e.id) {
        e = { ...e, show: e.show === 0 ? 1 : 0 };
        item = e;
      }
      return e;
    });
    this.setState({ courses });
    Fetch.post(ApiConst.DMR.UPADTE_COURSE, { courseId: item.id, show: item.show }, true);
  };

  renderBoxInfo = () => null;

  renderItem = (item, i) => {
    return (
      <TableRow padding={'none'} key={i}>
        <TableCell padding={'none'}>
          <TypographyContent>{item.name}</TypographyContent>
        </TableCell>

        <TableCell padding={'none'}>
          <ButtonBase onClick={this.onClickItem(item)} style={{ padding: 5 }}>
            {item.show === 0 ? <BsEyeSlashFill size={18} /> : <BsEyeFill size={18} color={'green'} />}
          </ButtonBase>
        </TableCell>
      </TableRow>
    );
  };

  renderTableHead = () => {
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell width={'80%'} padding={'normal'}>
            <TypographyTitle>{'Khóa học'}</TypographyTitle>
          </TableCell>
          <TableCell width={'20%'} padding={'none'}>
            <TypographyTitle>{'Hiển thị'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderContent = () => {
    const { courses } = this.state;
    return (
      <div>
        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Table style={{}}>
            {this.renderTableHead()}
            <TableBody>{courses.map(this.renderItem)}</TableBody>
          </Table>
        </Card>
      </div>
    );
  };
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return { courses: state.homeReducer.courses };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(CourseManagerScreen));
