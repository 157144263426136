import Configs from 'utils/Configs';

const IP_LOCAL = 'localhost';

const API = {
  local: `http://${IP_LOCAL}:8804/`,
  dev: 'http://139.180.155.130:8806/',
  prod: 'https://api.dungmori.com/api/'
};

const DUNGMORI_CDN = {
  local: 'https://dungmori.com/cdn/',
  dev: 'https://dungmori.com/cdn/',
  prod: 'https://dungmori.com/cdn/'
};

export default {
  API: API[Configs.mode],
  DUNGMORI_CDN: DUNGMORI_CDN[Configs.mode]
};
