import React from 'react';

export default ({ children, className, onClick, style = {} }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{ backgroundColor: 'transparent', textAlign: 'center', borderColor: 'transparent', padding: 10, ...style }}>
      {children}
    </button>
  );
};
