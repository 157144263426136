import { Card, InputBase, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Fetch from 'common/helpers/Fetch';
import Button from 'components/views/Button';
import PaginationView from 'components/views/PaginationView';
import ApiConst from 'consts/ApiConst';
import React from 'react';
import { withAlert } from 'react-alert';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { ProgressScreen, TypographyTitle } from 'screens/ProgressScreen';
import ItemTableInfo from './ItemTableInfo';
import styles from './styles.module.css';

class ModalAddUser extends ProgressScreen {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      page: 1,
      data: []
    };
  }

  async componentDidMount() {}

  show = async (group, userGroupList = []) => {
    this.group = group;
    this.users = null;
    this.userSearchs = null;
    this.setState({ page: 1, visible: true });
    const data = await Fetch.get(ApiConst.DMR.GET_ALL_USERS, {}, true);
    if (data.status === 200) {
      this.users = data.data;
      this.users = this.users.map((e) => {
        const find = userGroupList.find((e1) => e1.dmr_user_id === e.dmr_user_id);
        if (find) e = { ...e, isAdded: true };
        return e;
      });
      this.updateDataForPage(1);
    }
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onClickSuccess = () => {
    if (!this.user.getText() || !this.email.getText() || !this.code.getText() || !this.group.getText()) {
      alert('Mời bạn nhập đủ thông tin');
      return;
    }
  };

  onAddUser = (item) => {
    let { data } = this.state;
    data = data.map((e) => {
      if (e.id === item.id) {
        e = { ...e, isAdded: true };
        this.props.onAddUser(e);
      }
      return e;
    });
    this.users = this.users.map((e) => {
      if (e.id === item.id) e = { ...e, isAdded: true };
      return e;
    });
    this.setState({ data });
  };

  onChangeText = (ev) => {
    this.onSearch(ev.target.value);
  };

  renderItem = (item) => {
    return <ItemTableInfo key={item.id} item={item} onAddUser={this.onAddUser} groupId={this.group.id} />;
  };

  renderTableHead = () => {
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} width={'30%'} padding={'none'}>
            <TypographyTitle>{'Họ tên'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'Lớp - Khóa'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'SĐT'}</TypographyTitle>
          </TableCell>
          <TableCell width={'30%'} padding={'none'}>
            <TypographyTitle>{'Email'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trạng thái'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  render() {
    const { data = [], page = 1, maxPage = 1 } = this.state;
    return (
      <Modal
        onRequestClose={this.hide}
        ariaHideApp={false}
        isOpen={this.state.visible}
        style={{ overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' }, content: { width: '70%', padding: 20, margin: 'auto', borderRadius: 0 } }}>
        <div className={styles.container}>
          <Button style={{ position: 'absolute', right: 0, top: 0 }} onClick={this.hide}>
            <MdClose size={24} />
          </Button>
          <div className={styles.content}>
            <span className={styles.title}>Thêm học viên vào lớp {this.group?.name}</span>
            <center>
              <div className={styles.search}>
                <div className={styles.iconSearch}>
                  <SearchIcon fontSize={'small'} style={{ color: '#CACCCF' }} />
                </div>
                <InputBase
                  inputRef={(ref) => (this.input = ref)}
                  className={styles.input}
                  placeholder="Lọc theo tên, SĐT, email"
                  style={{ fontSize: 14 }}
                  onChange={this.onChangeText}
                />
              </div>
            </center>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: 680 }}>
              <Table>
                {this.renderTableHead()}
                <TableBody>{data.map(this.renderItem)}</TableBody>
              </Table>
              <PaginationView page={page} maxPage={maxPage} onClickPrev={this.onClickBackPage} onClickNext={this.onClickNextPage} />
            </Card>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withAlert()(ModalAddUser);
