import Fetch from 'common/helpers/Fetch';
import Funcs from 'common/helpers/Funcs';
import ApiConst from 'consts/ApiConst';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import GroupActionType from 'redux/types/GroupActionType';
import HomeActionType from 'redux/types/HomeActionType';
import Utils from 'utils/Utils';

export function* watchGroup() {
  yield takeLatest(GroupActionType.GET_LIST_GROUP, getGroupList);
  yield takeLatest(GroupActionType.GET_LIST_GROUP_USER, getGroupUserList);
}

function* getGroupList() {
  try {
    yield take(HomeActionType.GET_PARTNER_INFO_SUCCESS);
    let courses = yield select((state) => state.homeReducer.courses);
    const groups = yield call(Fetch.get, ApiConst.GROUP.GET_LIST, { userId: Utils.viewingUserId }, true);

    if (groups.status == Fetch.Status.SUCCESS) {
      let { data } = groups;
      data = data.map((e) => {
        const course = courses?.find((e1) => e1.id === e.course_id);
        if (e.subGroup) e.subGroup = Funcs.jsonParse(e.subGroup);
        if (course) e = { ...e, course_name: course.name };
        return e;
      });
      yield put({ type: GroupActionType.GET_LIST_GROUP_SUCCESS, groups: data });
      Utils.groups = data;
    }
  } catch (error) {
    Funcs.log(error);
    yield put({ type: GroupActionType.GET_LIST_GROUP_FAILED, data: [] });
  }
}

function* getGroupUserList() {
  try {
    yield take(HomeActionType.GET_LIST_COURSE_SUCCESS);
    let courses = yield select((state) => state.homeReducer.courses);
    if (!Utils.groups || Utils.groups?.length < 1) yield take(GroupActionType.GET_LIST_GROUP_SUCCESS);
    let groups = yield select((state) => state.groupReducer.groups);
    groups = groups.map((e) => {
      const course = courses.find((e1) => e1.id === e.course_id);
      if (course) e = { ...e, course_name: course.name };
      return e;
    });
    yield put({ type: GroupActionType.GET_LIST_GROUP_USER_SUCCESS, groups });
  } catch (error) {
    Funcs.log(error);
    yield put({ type: GroupActionType.GET_LIST_GROUP_USER_SUCCESS, data: [] });
  }
}
