import Fetch from 'common/helpers/Fetch';
import Funcs from 'common/helpers/Funcs';
import ApiConst from 'consts/ApiConst';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import HomeActionType from 'redux/types/HomeActionType';
import UserActionType from 'redux/types/UserActionType';
import Configs from 'utils/Configs';

export function* watchHome() {
  yield takeLatest(HomeActionType.GET_PARTNER_INFO, getPartnerData);
  yield takeLatest(HomeActionType.GET_LIST_COURSE, getListCourse);
}

function* getPartnerData() {
  yield take(UserActionType.UPDATE_USER_INFO);
  try {
    let partnerInfo = yield call(Fetch.get, ApiConst.DMR.GET_PARTNER_INFO, { partnerId: Configs.user.id }, true);
    if (partnerInfo.status === 200) {
      partnerInfo = partnerInfo.data;
    }
    yield put({ type: HomeActionType.GET_PARTNER_INFO_SUCCESS, partnerInfo });
  } catch (error) {
    Funcs.log(error);
    yield put({ type: HomeActionType.GET_PARTNER_INFO_FAILED });
  }
}

function* getListCourse() {
  try {
    let courses = yield call(Fetch.get, ApiConst.DMR.GET_LIST_COURSE, {}, true);
    if (courses.status === 200) {
      courses = courses.data;
      yield put({ type: HomeActionType.GET_LIST_COURSE_SUCCESS, courses });
    }
  } catch (error) {
    Funcs.log(error);
  }
}
