import { ButtonBase, Checkbox, Typography } from '@material-ui/core';
import React, { Component, PureComponent } from 'react';
import ReactExport from 'react-export-excel';
import styles from './styles.module.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ItemMenu extends Component {
  onClickCheck = () => {
    const { item } = this.props;
    if (this.props.onClickCheck) this.props.onClickCheck(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div style={{ flexGrow: 1, display: 'flex', height: 35, alignItems: 'center' }}>
        <Checkbox color={'primary'} onClick={() => this.onClickCheck(item)} checked={!!item?.isChecked} />
        <Typography component={'p'} variant={'body2'} style={{ fontWeight: '500', color: '#000', fontSize: 14 }}>
          {item.title}
        </Typography>
      </div>
    );
  }
}

export default class ExportData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { listDataExport: [], exportFields: props.exportFields.map((e) => ({ ...e, isChecked: true })) };
  }

  componentDidMount() {
    this.updateDataExport();
  }

  updateDataExport = () => {
    let { exportFields } = this.state;
    const { data } = this.props;
    const listDataExport = data.map((item, index) => {
      const data = { index: index + 1, name: item.name };
      for (let i = 0; i < exportFields.length; i += 1) {
        if (exportFields[i].isChecked) data[exportFields[i].key] = item[exportFields[i].key] || exportFields[i].default;
      }
      return data;
    });
    this.setState({ listDataExport });
  };

  onClickCheck = (item) => {
    let { exportFields } = this.state;
    exportFields = exportFields.map((e) => {
      if (e.key === item.key) e = { ...e, isChecked: !e.isChecked };
      return e;
    });
    this.setState({ exportFields }, this.updateDataExport);
  };

  render() {
    const { exportFields, listDataExport, currentIndex } = this.state;
    if (!listDataExport) return null;

    return (
      <div style={{ width: 200, padding: 10, paddingLeft: 10, paddingRight: 10 }}>
        <Typography
          component={'p'}
          variant={'body2'}
          style={{ fontWeight: '600', color: '#000', marginBottom: 5, fontSize: 15, marginLeft: 10, marginTop: 10 }}>
          Trích xuất dữ liệu
        </Typography>
        {exportFields.map((item, index) => {
          return <ItemMenu key={index} item={item} index={index} currentIndex={currentIndex} onClickCheck={this.onClickCheck} />;
        })}
        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
          <ButtonBase
            onClick={this.props.onClickCancel}
            className={styles.buttonContainer}
            style={{ backgroundColor: '#C4C4C4', borderRadius: 5, marginRight: 8 }}>
            <Typography component={'p'} variant={'body2'}>
              Cancel
            </Typography>
          </ButtonBase>
          <ExcelFile
            element={
              <ButtonBase className={styles.buttonContainer} style={{ backgroundColor: '#96D962', borderRadius: 5, marginLeft: 8 }}>
                <Typography component={'p'} variant={'body2'}>
                  Tải về
                </Typography>
              </ButtonBase>
            }>
            <ExcelSheet data={listDataExport} name="Leaves">
              <ExcelColumn label="Stt" value="index" />
              <ExcelColumn label="Họ tên" value="name" />
              {exportFields.map((e) => (
                <ExcelColumn key={e.key} label={e.title} value={e.key} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    );
  }
}
