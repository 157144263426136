import { ButtonBase, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ModalConfirm from 'common/components/modals/ModalConfirm';
import Fetch from 'common/helpers/Fetch';
import Time from 'common/helpers/Time';
import ApiConst from 'consts/ApiConst';
import React from 'react';
import { withAlert } from 'react-alert';
import { MdAddCircleOutline } from 'react-icons/md';
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleFill } from 'react-icons/ri';
import { withRouter } from 'react-router-dom';
import FilterView from 'screens/BaseScreen/FilterView';
import { ProgressScreen, TypographyTitle } from 'screens/ProgressScreen';
import Utils from 'utils/Utils';
import ItemTableInfo from './ItemTableInfo';
import ModalAddUser from './ModalAddUser';
import styles from './styles.module.css';

class GroupDetailScreen extends ProgressScreen {
  constructor(props) {
    super(props);
    this.state = {
      groupId: parseInt(this.props.match.params?.groupId) || 0,
      page: 1,
      maxPage: 1,
      data: []
    };
    this.filterList = [
      { title: 'Trình độ', key: 'japanese_level', default: 'Chưa có' },
      { title: 'Khoá - lớp', key: 'group_name', default: 'Chưa có' },
      { title: 'Ngày bắt đầu', key: 'start_date_str', default: 0 },
      { title: 'SĐT', key: 'phone', default: 'Chưa có' },
      { title: 'Email', key: 'email', default: 'Chưa đạt' }
    ];
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const res = await Fetch.get(ApiConst.DMR.GET_USERS_INFO, { groupId: this.state.groupId }, true);
    if (res.status === 200) {
      this.users = res.data.map((e) => {
        const group = Utils.findGroupById(e.group_id);
        e.start_date_str = e.created_at ? Time.format(e.created_at, 'DD/MM/YYYY') : 'Chưa rõ';
        e.group_name = group.name;
        return e;
      });
      this.updateDataForPage(1);
    }
  };

  onSelectedGroup = (groupId) => {
    this.setState({ groupId, data: [] }, this.getData);
    this.props.history.replace({ pathname: `${groupId}` });
  };

  onClickDeleteItem = (item) => {
    ModalConfirm.show({
      title: 'Xóa học viên',
      content: 'Bạn có chắc chắn muốn xóa ' + item.name + ' ra khỏi lớp',
      onClickOk: async () => {
        const res = await Fetch.post(ApiConst.GROUP.DELETE_USER, { groupId: this.state.groupId, dmrUserId: item.dmr_user_id }, true);
        if (res.status !== 200) {
          this.props.alert.error('Thất bại');
        } else {
          this.props.alert.success('Thành công');
          this.users = this.users.filter((e) => e.id !== item.id);
          this.userSearchs = this.userSearchs?.filter((e) => e.id !== item.id);
          this.updateDataForPage(this.page);
        }
      }
    });
  };

  renderBoxInfo = () => null;

  onCickAddUser = () => {
    const group = Utils.findGroupById(this.state.groupId);
    this.ModalAddUser.show(group, this.users);
  };

  onAddUser = (item) => {
    this.users.push(item);
    this.updateDataForPage(1);
  };

  renderButton = () => {
    const style = { padding: 10, margin: 5, display: 'inline-block' };
    return (
      <div className={styles.viewIcon}>
        <ButtonBase onClick={this.onCickAddUser} style={style}>
          <MdAddCircleOutline size={22} color={'#96D962'} />
          <Typography style={{ fontSize: 14, marginTop: 2, fontWeight: '500' }}>{`Thêm học viên`}</Typography>
        </ButtonBase>
      </div>
    );
  };

  renderFilterView = () => {
    return (
      <FilterView
        searchHint={'Tìm kiếm theo tên, sđt, email'}
        renderButton={this.renderButton}
        onSearch={this.onSearch}
        exportFields={this.filterList}
        data={this.state.data}
      />
    );
  };

  renderTableHead = () => {
    const { checkAll } = this.state;
    return (
      <TableHead style={{}}>
        <TableRow style={{ backgroundColor: 'rgba(232, 232, 232, 0.2)', fontWeight: 'bold' }}>
          <TableCell width={'10%'} align={'center'}>
            <ButtonBase style={{ display: 'inline-block' }} onClick={this.onClickCheckAll}>
              {checkAll ? <RiCheckboxMultipleFill size={20} /> : <RiCheckboxMultipleBlankLine size={20} />}
              <Typography style={{ fontSize: 9, padding: 0, lineHeight: 0.5, fontWeight: 'bold' }}>{'Chọn tất cả'}</Typography>
            </ButtonBase>
          </TableCell>
          <TableCell width={'20%'} padding={'none'}>
            <TypographyTitle>{'Họ tên'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Trình độ'}</TypographyTitle>
          </TableCell>
          <TableCell width={'10%'} padding={'none'}>
            <TypographyTitle>{'Lớp - Khóa'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'SĐT'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'Email'}</TypographyTitle>
          </TableCell>
          <TableCell width={'15%'} padding={'none'}>
            <TypographyTitle>{'Ngày bắt đầu'}</TypographyTitle>
          </TableCell>
          <TableCell width={'5%'} padding={'none'}>
            <TypographyTitle>{'Xóa'}</TypographyTitle>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderItem = (item) => {
    return <ItemTableInfo key={item.id} item={item} onClickItem={this.onClickItem} onClickDeleteItem={this.onClickDeleteItem} />;
  };

  renderModal = () => {
    return <ModalAddUser ref={(ref) => (this.ModalAddUser = ref)} onAddUser={this.onAddUser} />;
  };
}

export default withAlert()(withRouter(GroupDetailScreen));
