import { ButtonBase, MenuItem, Select, Typography } from '@material-ui/core';
import Colors from 'assets/Colors';
import Fetch from 'common/helpers/Fetch';
import Button from 'components/views/Button';
import ApiConst from 'consts/ApiConst';
import React from 'react';
import { withAlert } from 'react-alert';
import { BiUserCircle } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import styles from './styles.module.css';
import GroupActionCreator from 'redux/actionCreators/GroupActionCreator';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';

class ModalAddGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      courseIndex: -1,
      isShowSelect: false
    };
  }

  show = () => {
    HomeActionCreator.getListCourse();
    this.setState({ visible: true, courseIndex: -1 });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onClickSubmit = async () => {
    const { courseIndex } = this.state;
    const { courses = [], parentId, isGroup } = this.props;
    const name = this.inputName.value.trim();
    const course = courses[courseIndex];
    if (!name || name?.length < 2) {
      this.props.alert.error(isGroup ? 'Tên khóa không hợp lệ' : 'Tên lớp không hợp lệ');
      return false;
    }
    if (!course || course?.id < 2) {
      this.props.alert.error('Xin vui lòng chọn khóa học hợp lệ');
      return false;
    }
    const res = await Fetch.post(ApiConst.GROUP.ADD, { name, parentId, courseId: course?.id }, true);
    if (res.status === 200) {
      GroupActionCreator.addGroup({ ...res.data, course_name: course?.name, status: 1 });
      this.hide();
    }
  };

  onSelectClose = () => {
    this.setState({ isShowSelect: false });
  };

  onSelectOpen = () => {
    this.setState({ isShowSelect: true });
  };

  onSelectChange = (event) => {
    const index = event.target.value;
    this.setState({ courseIndex: index });
  };

  render() {
    const { visible, courseIndex, isShowSelect } = this.state;
    const { courses = [], isGroup } = this.props;
    return (
      <Modal
        onRequestClose={this.hide}
        ariaHideApp={false}
        isOpen={visible}
        style={{
          overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
          content: { width: '30%', height: isGroup ? 230 : 280, padding: 20, margin: 'auto', borderRadius: 0 }
        }}>
        <div className={styles.boxModal}>
          <Button style={{ position: 'absolute', right: 0, top: 0 }} onClick={this.hide}>
            <MdClose size={24} />
          </Button>
          <div className={styles.content}>
            <span className={styles.title}>{isGroup ? 'Tạo khóa' : 'Tạo lớp'}</span>
            <div className={styles.viewInput}>
              <BiUserCircle size={18} color={'#222'} />
              <span style={{ marginLeft: 10 }}>{isGroup ? 'Tên khóa' : 'Tên lớp'}:</span>
              <input ref={(ref) => (this.inputName = ref)} className={styles.inputContainer} type="text" required="" onChange={this.onChangeText} />
            </div>
            {!isGroup && (
              <div className={styles.viewInput}>
                <BiUserCircle size={18} color={'#222'} />
                <span style={{ marginLeft: 10 }}>Cấp độ:</span>
                {courses?.length > 0 && (
                  <Select
                    style={{ flex: 1, marginLeft: 10 }}
                    disableUnderline
                    open={isShowSelect}
                    onClose={this.onSelectClose}
                    onOpen={this.onSelectOpen}
                    onChange={this.onSelectChange}
                    value={courseIndex >= 0 ? courses[courseIndex].name : 'Chọn khóa học'}
                    renderValue={() => (
                      <Typography style={{ color: Colors.green, fontWeight: '600' }}>{courseIndex >= 0 ? courses[courseIndex].name : 'Chưa chọn'}</Typography>
                    )}>
                    {courses?.map((item, index) => {
                      if (item.show === 0) return null;
                      return (
                        <MenuItem key={index} value={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </div>
            )}
            <ButtonBase className={styles.btnSuccess} style={{ padding: 0, backgroundColor: Colors.green, marginTop: 20 }} onClick={this.onClickSubmit}>
              Xác nhận
            </ButtonBase>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    courses: state.homeReducer.courses
  };
};
export default withAlert()(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalAddGroup));
