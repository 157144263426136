import { ButtonBase, Menu, MenuItem } from '@material-ui/core';
import DataConst from 'consts/DataConst';
import RouteConst from 'consts/RouteConst';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Configs from 'utils/Configs';
import styles from './styles.module.css';

export default () => {
  const history = useHistory();
  const location = useLocation();
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [user, headerTitle] = useSelector((state) => [state.userReducer.user, state.homeReducer.headerTitle]);
  let title = location?.data?.title || location?.data?.name;
  if (!title) {
    const path = location.pathname?.replace('/', '') || 'progress';
    const find = RouteConst[path];
    title = find?.title || headerTitle;
  }
  const onClick = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  const onClickAdmin = () => {
    history.replace('/admin/partner');
  };

  const onClickLogout = () => {
    handleClose();
    Configs.token = null;
    Configs.user = {};
    localStorage.setItem(DataConst.USER_TOKEN, null);
    history.replace('/');
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.textTitle}>{title}</div>
      <div>
        <ButtonBase onClick={onClick}>
          {/* <MdNotificationsNone size={24} color={'#9E9E9E'} /> */}
          <div className={styles.infoName}>
            <div className={styles.textUserName}>{user?.name}</div>
            <div className={styles.textInfo}>MS: {user?.partner_id}</div>
          </div>
          <img className={styles.avatar} src={'/images/partner_avatar.png'} />
        </ButtonBase>
        <Menu
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={handleClose}
          style={{ marginTop: 60 }}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
          <MenuItem onClick={handleClose}>Thông tin đối tác</MenuItem>
          {user.role === 0 && <MenuItem onClick={onClickAdmin}>Quản trị</MenuItem>}
          <MenuItem onClick={onClickLogout}>Đăng xuất</MenuItem>
        </Menu>
      </div>
    </div>
  );
};
