import Store from 'redux/Store';
import UserActionType from 'redux/types/UserActionType';

export default {
  login: (email, password, errorCallback) => Store.dispatch({ type: UserActionType.LOGIN, email, password, errorCallback }),
  autoLogin: (callback) => Store.dispatch({ type: UserActionType.AUTO_LOGIN, callback }),
  removeUser: (user) => Store.dispatch({ type: UserActionType.REMOVE_USER, user }),
  addUser: (user) => Store.dispatch({ type: UserActionType.ADD_USER, user }),
  updateUser: (user) => Store.dispatch({ type: UserActionType.UPDATE_USER, user })
};
