import ModalConfirm from 'common/components/modals/ModalConfirm';
import ModalLoading from 'common/components/modals/ModalLoading';
import ModalUserInfo from 'components/modals/ModalUserInfo';
import DrawerMenuView from 'components/views/DrawerMenuView';
import HeaderView from 'components/views/HeaderView';
import RouteConst from 'consts/RouteConst';
import React, { useEffect, useState } from 'react';
import { Ring } from 'react-awesome-spinners';
import { useSelector } from 'react-redux';
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GroupActionCreator from 'redux/actionCreators/GroupActionCreator';
import HomeActionCreator from 'redux/actionCreators/HomeActionCreator';
import UserActionCreator from 'redux/actionCreators/UserActionCreator';
import LoginScreen from 'screens/LoginScreen';
import ConfirmPassword from 'screens/LoginScreen/ConfirmPassword';
import ForgotPassword from 'screens/LoginScreen/ForgotPassword';
import Configs from 'utils/Configs';
import Utils from 'utils/Utils';
import './App.css';

export default () => {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.userReducer.user);
  useEffect(() => {
    async function init() {
      await Utils.init();

      // Auto login
      HomeActionCreator.getPartnerInfo();
      GroupActionCreator.getGroups();
      HomeActionCreator.getListCourse();
      if (Configs.token) UserActionCreator.autoLogin(() => setLoading(false));
      else setLoading(false);
    }
    init();
    return () => {
      Utils.release();
    };
  }, []);

  // Loading
  if (loading) {
    return (
      <div className="rootView centerContent">
        <Ring color={'gray'} />
      </div>
    );
  }

  // Login
  if (!user?.id) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <LoginScreen />
          </Route>
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/confirm-password" component={ConfirmPassword} />
        </Switch>
      </BrowserRouter>
    );
  }

  // Succss
  const routerKeys = Object.keys(RouteConst);
  return (
    <Router>
      <div className="rootView">
        <DrawerMenuView />
        <div className="rootContent">
          <HeaderView />
          <Switch>
            {routerKeys.map((key) => {
              const item = RouteConst[key];
              return <Route exact key={item.path} path={`/${item.path}`} component={item.component} />;
            })}
          </Switch>
        </div>
        <ModalUserInfo />
        <ModalConfirm />
        <ModalLoading />
      </div>
    </Router>
  );
};
